import { Controller, FieldError, FieldErrorsImpl, Merge } from "react-hook-form";
import { CONST } from "shared/const";
import { MASKS } from "shared/const/masks";
import { Sizes } from "shared/types/mantine";
import { MaskStyledInput } from "../_masks/MaskStyledInput";

type Props = {
  name: string;
  control: any;
  size?: Sizes;
  label?: string;
  required?: boolean;
  error?: string | FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined;
};

const { REGEX } = CONST;

const rules = (required?: boolean) => ({
  required: required
    ? {
        value: true,
        message: "Поле телефон обязательно для заполнения",
      }
    : {},
  pattern: {
    value: REGEX.phone,
    message: "Введите номер телефона",
  },
});

export const PhoneInput = ({ name, control, size, required, label, error }: Props) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules(required) as any}
      render={({ field: { onChange, onBlur, value } }) => {
        return (
          <MaskStyledInput
            onBlur={onBlur}
            value={value}
            label={label}
            type={"tel"}
            error={error as any}
            mask={MASKS.mobilePhone.mask}
            definitions={MASKS.mobilePhone.definitions}
            placeholder={MASKS.mobilePhone.placeholder}
            onAccept={onChange}
            required={required}
            onChange={onChange}
            size={size as any}
            overwrite
          />
        );
      }}
    />
  );
};
