export const OFFER_STATUSES = {
  verify: {
    label: "На проверке",
    value: "verify",
    color: "gold",
  },
  noVerified: {
    label: "Отклонен",
    value: "noVerified",
    color: "red",
  },
  confirmed: {
    label: "Подтверждено",
    value: "confirmed",
    color: "green",
  },
  offerTemplateVerify: {
    label: "Инвестпредложение на проверке",
    value: "offerTemplateVerify",
    color: "red",
  },
  fundraising: {
    label: "Сбор средств",
    value: "fundraising",
    color: "gold",
  },
  completed: {
    label: "Завершено",
    value: "completed",
    color: "grey",
  },
  toCompletion: {
    label: "Готовится к завершению",
    value: "toCompletion",
    color: "gold",
  },
  completedEarlier: {
    label: "Завершилось раньше срока",
    value: "completedEarlier",
    color: "grey",
  },
  cancelled: {
    label: "Отменено",
    value: "cancelled",
    color: "red",
  },
};
