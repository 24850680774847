import { Button, Collapse, Paper, Stack } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useTeamMembers } from "modules/projectRole/hooks/useTeamMembers";
import { FormTemplate } from "shared/ui/_forms/FormTemplate";
import { config, teamMemberSchema } from "./config";

const { buttonConfig, form } = config;

type Props = {
  handleAddMember: (form: any) => void;
};
export const MainTeamAddMemberForm = ({ handleAddMember }: Props) => {
  const [opened, { toggle }] = useDisclosure(false);
  const currentButtonConfig = buttonConfig[Number(opened)];
  const buttonTitle = currentButtonConfig.name;
  const buttonVariant = currentButtonConfig.variant;
  const {
    fetchSetItems: { pending },
  } = useTeamMembers();

  const onSubmit = (form: any) => {
    handleAddMember(form);
    toggle();
  };

  return (
    <Paper>
      <Stack>
        <Button loading={pending} onClick={toggle} variant={buttonVariant}>
          {buttonTitle}
        </Button>
        <Collapse in={opened}>
          <FormTemplate
            onSubmit={onSubmit}
            formSchema={teamMemberSchema}
            form={form}
            textButton="Добавить члена команды"
            resetAfterSubmit
          />
        </Collapse>
      </Stack>
    </Paper>
  );
};
