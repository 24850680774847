import { ZodErrorMap, ZodIssueCode, ZodParsedType } from "zod";

export const zodErrorMap: ZodErrorMap = (issue, _ctx) => {
  // all cases: https://github.com/colinhacks/zod/blob/18a0c288a361c0f7c269829f576494280ec9b1a8/deno/lib/locales/en.ts#L6
  switch (issue.code) {
    case ZodIssueCode.invalid_type:
      if (issue.received === ZodParsedType.undefined) {
        return {
          message: "Поле обязательно для заполнения",
        };
      }
  }

  return {
    message: _ctx.defaultError,
  };
};
