import { CreateDealFxProps, dealsFx, GetByIdDealParams, SignFxProp } from "shared/api/deals";
import { Deal } from "shared/types/deals";
import { FxSlice } from "shared/types/store";
import { createSliceNew } from "stores/_helpers/factories/createSliceNew";

export type CreateDealFxs = {
  fetchCreate: FxSlice<CreateDealFxProps>;
  fetchGet: FxSlice<GetByIdDealParams>;
  fetchSign: FxSlice<SignFxProp>;
};

const { create, getById, sign } = dealsFx;

export const dealSlice = createSliceNew<Deal, CreateDealFxs>({
  key: "deal",
  fxs: { fetchCreate: create, fetchGet: getById, fetchSign: sign },
});
