import { useListState } from "@mantine/hooks";
import React from "react";
import { uploadFileFX } from "shared/api/files";
import { useFetch } from "shared/hooks/useFetch";
import { useSendError } from "shared/hooks/useSendError";
import { useSessionID } from "shared/hooks/_auth/useSessionID";

export const useUploadFiles = () => {
  const [fileLinks, handlers] = useListState<string>();
  const { data, pending, fetchData, error, reset } = useFetch(uploadFileFX);
  const sessionID = useSessionID()!;

  React.useEffect(() => {
    if (data) {
      handlers.append(data);
      reset();
    }
  }, [data]);

  useSendError(error);

  const handleResetFileLinks = () => handlers.setState([]);
  const handleUploadFile = async (file: File) => {
    handleResetFileLinks();
    await fetchData({ sessionID, file });
  };
  const handleUploadFiles = async (files: File[]): Promise<any> => {
    const filesCopy = [...files].slice(1);
    const file = files[0];
    await fetchData({ sessionID, file });
    if (filesCopy.length) return await handleUploadFiles(filesCopy);
  };

  return { fileLinks, handleUploadFile, handleUploadFiles, pending, error, handlers };
};
