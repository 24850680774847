import {
  IconBrush,
  IconBuildingStore,
  IconBusinessplan,
  IconCoins,
  IconHome,
  IconNews,
  IconRocket,
  IconUserCircle,
  IconWallet,
} from "@tabler/icons-react";
import { ROUTES } from "shared/const/ROUTES";
import { NavbarData } from "shared/types/navbar";

export const desktopConfig: NavbarData = [
  { type: "investment", label: "Инвестировать", icon: IconRocket, link: "/deals/create" },
  {
    type: "link",
    link: "/main",
    label: "Главная",
    icon: IconHome,
  },
  {
    type: "link",
    link: "/accounts",
    param: "individual",
    label: "Счета",
    icon: IconWallet,
  },
  {
    type: "link",
    link: "/projects",
    label: "Проекты",
    icon: IconBuildingStore,
  },
  { type: "link", link: "/deals", param: "all", label: "Сделки", icon: IconCoins },
  {
    type: "link",
    link: "/news",
    label: "Новости",
    icon: IconNews,
  },
  {
    type: "link",
    link: "/profile",
    label: "Профиль",
    param: "all",
    icon: IconUserCircle,
  },
];

export const desktopProjectConfig: NavbarData = [
  {
    type: "link",
    link: ROUTES.PROJECT.MAIN,
    label: "Главная",
    icon: IconHome,
  },
  {
    type: "link",
    link: ROUTES.PROJECT.ACCOUNT.ROOT,
    label: "Счет",
    icon: IconWallet,
  },
  {
    type: "link",
    link: ROUTES.PROJECT.ROUNDS.ALL_ROUNDS,
    label: "Раунды",
    icon: IconBusinessplan,
  },
  {
    type: "link",
    link: ROUTES.PROJECT.CARD.SETTINGS.COMMON,
    label: "Настройки карточки",
    icon: IconBrush,
  },
  {
    type: "link",
    link: ROUTES.PROJECT.PROFILE.ALL,
    label: "Профиль",
    icon: IconUserCircle,
  },
];
