import { ROLES } from "shared/const/roles";

export type PrivateTypes =
  | "emailVerify"
  | keyof typeof ROLES
  | "createAccount"
  | "accountVerified"
  | "cardSettings"
  | "rounds"
  | "projectAccount";
export type Conditions = Record<PrivateTypes, { value: boolean; errorMessage: string }>;

export const checkAccess = (
  conditions: Conditions,
  type: PrivateTypes | PrivateTypes[],
): {
  hasAccess: boolean;
  errorMessage?: string;
} => {
  const types = Array.isArray(type) ? type : [type];

  const errorConditionType = types.find((type) => !conditions[type]?.value);

  if (errorConditionType) {
    return {
      hasAccess: false,
      errorMessage: conditions[errorConditionType].errorMessage,
    };
  }

  return {
    hasAccess: true,
  };
};
