import { Text, useMantineTheme } from "@mantine/core";
import { Sizes } from "shared/types/mantine";

type Props = {
  size?: Sizes;
  error?: string | null;
};

export const ErrorText = ({ size = "sm", error }: Props) => {
  const {
    colors: { error: errorColors },
  } = useMantineTheme();
  return error ? (
    <Text size={size} c={errorColors[6]}>
      {error}
    </Text>
  ) : null;
};
