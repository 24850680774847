import { NumberInput as NumberInputMantine } from "react-hook-form-mantine";
import { NumberInput as NumberInputType } from "shared/types/forms";
import { Sizes } from "shared/types/mantine";
import { numberInputConfig } from "./config";

import styles from "./index.module.scss";

type Props = Omit<NumberInputType, "type" | "setValue"> & {
  control: any;
  size?: Sizes;
};

const rules = (required?: boolean) => ({
  required: required
    ? {
        value: true,
        message: "Поле обязательно для заполнения",
      }
    : {},
});
export const NumberInput = ({
  numberType,
  required,
  error,
  size,
  suffix: defaultSuffix,
  ...props
}: Props) => {
  const suffix = defaultSuffix ? defaultSuffix : numberType && numberInputConfig[numberType];

  return (
    <NumberInputMantine
      {...props}
      className={styles.numberInputMantine}
      rules={rules(required) as any}
      defaultValue={null}
      suffix={suffix}
      required={required}
      type={"text"}
      size={size}
      clampBehavior={"strict"}
      thousandSeparator={" "}
    />
  );
};
