import { AspectRatio, CloseButton, Overlay, Paper } from "@mantine/core";
import styles from "./index.module.scss";

type Props = {
  onRemove: (index: number) => void;
  imageUrl: string;
  index: number;
};

export const ImagePreview = ({ onRemove, imageUrl, index }: Props) => {
  const handleRemove = () => {
    onRemove(index);
  };

  return (
    <Paper bg={"dark.6"} p={0} style={{ overflow: "hidden" }}>
      <AspectRatio ratio={16 / 9} w={200} mx="auto" display={""}>
        <img src={imageUrl} />
        <Overlay className={styles.overlay} zIndex={1} color="#000" backgroundOpacity={0.85}>
          <CloseButton onClick={handleRemove} />
        </Overlay>
      </AspectRatio>
    </Paper>
  );
};
