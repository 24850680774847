import { PrivateRoute } from "components/_private/PrivateRoute";
import { newsRoutes } from "modules/investorRole/router/routes/news";
import { ROLES } from "shared/const/roles";
import { AnyObject } from "shared/types/common";
import { Route } from "shared/types/routes";
import { accountsRoutes } from "./routes/accounts";
import { dealsRoutes } from "./routes/deals";
import { mainRoute } from "./routes/main";
import { profileRoutes } from "./routes/profile";
import { projectRoutes } from "./routes/projects";

export const investorRoleRoutes: AnyObject<Route> = {
  investorRoleRoutes: {
    Element: () => <PrivateRoute type={ROLES.investor} />,
    children: {
      ...mainRoute,
      ...accountsRoutes,
      ...projectRoutes,
      ...dealsRoutes,
      ...profileRoutes,
      ...newsRoutes,
    },
  },
};
