import { MantineSize, Text } from "@mantine/core";
import { NumberBody } from "shared/types/table";

export type TableNumberProps = {
  body: NumberBody;
  size: MantineSize | string | undefined;
};
export const TableNumber = ({ size, body }: TableNumberProps) => {
  return <Text size={size}>{body.value.toLocaleString()}</Text>;
};
