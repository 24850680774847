import { LazyAccountPage } from "modules/investorRole/pages/AccountsPage/AccountPage/lazy";
import { LazyCreateAccountPage } from "modules/investorRole/pages/AccountsPage/CreateAccountPage/lazy";
import { LazyAccountsPage } from "modules/investorRole/pages/AccountsPage/lazy";
import { LazyQualifyAccountPage } from "modules/investorRole/pages/AccountsPage/QualifyAccountPage/lazy";

import { PrivateRoute } from "components/_private/PrivateRoute";
import { ROUTES } from "shared/const/ROUTES";
import { AnyObject } from "shared/types/common";
import { Route } from "shared/types/routes";

export const accountsRoutes: AnyObject<Route> = {
  accounts: {
    Element: LazyAccountsPage,
    path: "accounts",
    children: {
      account: {
        Element: LazyAccountPage,
        path: ":id",
      },
    },
  },
  qualify: {
    Element: LazyQualifyAccountPage,
    path: "accounts/:id/qualify",
  },

  createAccount: {
    Element: () => <PrivateRoute type={"emailVerify"} backPath={"/accounts"} />,
    children: {
      createAccount: {
        path: ROUTES.STEP_FORMS.CREATE_ACCOUNT.REL,
        Element: LazyCreateAccountPage,
      },
    },
  },
};
