import { PrivateRoute } from "components/_private/PrivateRoute";
import { LazyCreateDealProjectPage } from "modules/investorRole/pages/DealsPage/CreateDealPage/CreateDealProjectPage/lazy";
import { LazyCreateDealPage } from "modules/investorRole/pages/DealsPage/CreateDealPage/lazy";
import { LazyDealPage } from "modules/investorRole/pages/DealsPage/DealPage/lazy";
import FilterDealsPage from "modules/investorRole/pages/DealsPage/FilterDealsPage";
import { LazyDealsPage } from "modules/investorRole/pages/DealsPage/lazy";
import { AnyObject } from "shared/types/common";
import { Route } from "shared/types/routes";

export const dealsRoutes: AnyObject<Route> = {
  private: {
    Element: () => <PrivateRoute type={["createAccount", "accountVerified"]} backPath={"/"} />,
    children: {
      deals: {
        Element: LazyDealsPage,
        path: "deals",
        children: {
          filter: {
            Element: FilterDealsPage,
            path: ":status",
          },
        },
      },
      create: {
        Element: LazyCreateDealPage,
        path: "deals/create",
        children: {
          project: {
            Element: LazyCreateDealProjectPage,
            path: ":id",
          },
        },
      },
      deal: {
        Element: LazyDealPage,
        path: "deal/:id",
      },
    },
  },
};
