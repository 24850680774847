import { Stack } from "@mantine/core";
import { useCardSettings } from "modules/projectRole/hooks/useCardSettings";
import { useTeamMembers } from "modules/projectRole/hooks/useTeamMembers";
import { CARD_SETTINGS_NAMES } from "modules/projectRole/pages/CardSettingsPage/config";
import { createContext, useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { createIdHelper } from "shared/helpers/createId";
import { TeamMember } from "shared/types/projects";
import { MainTeamAddMemberForm } from "./components/MainTeamAddMemberForm";
import { MainTeamMembers } from "./components/MainTeamMembers";

type CardSettingsTeamMemberContextType = {
  handleDeleteTeamMember: (index: number) => void;
};

export const CardSettingsTeamMemberContext =
  createContext<CardSettingsTeamMemberContextType | null>(null);
export const CardSettingsTeamPage = () => {
  const cardSettings = useCardSettings(true);
  const { data, handleGetByIds, handleSetTeamMembers } = useTeamMembers();
  const { setValue, control } = useFormContext();

  const teamMembersIds = useWatch({
    control,
    name: CARD_SETTINGS_NAMES.teamMembers,
  }) as string[];

  const members = (data || []).filter(
    ({ id }) => teamMembersIds && teamMembersIds.includes(id as string),
  );

  useEffect(() => {
    if (!cardSettings.data || data) return;

    handleGetByIds(cardSettings.data.teamMembers || []);
  }, [cardSettings.data]);

  const handleAddMember = (form: TeamMember) => {
    if (!cardSettings.data) return;

    const newMember: TeamMember = {
      id: createIdHelper(),
      firstName: form.firstName,
      lastName: form.lastName,
      position: form.position,
      index: Number(members?.length),
      photo: form.photo,
      project: cardSettings.data.project,
    };

    const newTeamMembers: TeamMember[] = members
      ? [...members, newMember].map((member, index) => ({ ...member, index }))
      : [newMember];

    handleSetTeamMembers(newTeamMembers);

    setValue(
      CARD_SETTINGS_NAMES.teamMembers,
      teamMembersIds?.length ? [...teamMembersIds, newMember.id] : [newMember.id],
    );
  };

  const handleDeleteTeamMember = (index: number) => {
    const { id } = members[index];

    const filteredIds = teamMembersIds.filter((memberId) => memberId !== id);
    setValue(CARD_SETTINGS_NAMES.teamMembers, filteredIds);
  };

  return (
    <CardSettingsTeamMemberContext.Provider value={{ handleDeleteTeamMember }}>
      <Stack>
        <MainTeamAddMemberForm handleAddMember={handleAddMember} />
        <MainTeamMembers members={members} />
      </Stack>
    </CardSettingsTeamMemberContext.Provider>
  );
};
