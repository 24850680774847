import { Navigate, Outlet } from "react-router-dom";
import { CONST } from "shared/const";
import { useCheckAuth } from "shared/hooks/_auth/useCheckAuth";
import { CenterContainer } from "shared/ui/_containers/CenterContainer";
import { MainLoader } from "shared/ui/_loaders/MainLoader";

const { SIGN_IN } = CONST.PAGES.NOT_AUTH;

export const CheckAuth = () => {
  const checkAuth = useCheckAuth();
  const loading = checkAuth.pending || !checkAuth.data;
  const notAuth = !checkAuth.data && checkAuth.error;

  if (notAuth) return <Navigate to={"/" + SIGN_IN.PATH} />;

  if (loading) {
    return (
      <CenterContainer>
        <MainLoader />
      </CenterContainer>
    );
  }

  return <Outlet />;
};
