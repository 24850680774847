import { MantineSize, NumberFormatter, Text } from "@mantine/core";
import { CurrencyBody } from "../../../../../../types/table";

export type TableCurrencyProps = {
  body: CurrencyBody;
  size: MantineSize | string | undefined;
};

export const TableCurrency = ({ size, body }: TableCurrencyProps) => {
  return (
    <Text size={size} style={{ textWrap: "nowrap" }}>
      <NumberFormatter suffix={" ₽"} thousandSeparator={" "} value={(body as CurrencyBody).value} />
    </Text>
  );
};
