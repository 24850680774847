import CheckSMSPage from "pages/_service/CheckSMSPage";
import React from "react";

const NotFoundPage = React.lazy(() => import("pages/_service/NotFoundPage"));
const VerifyEmailPage = React.lazy(() => import("pages/_service/VerifyEmailPage"));
const ForbiddenPage = React.lazy(() => import("pages/_service/ForbiddenPage"));

export const serviceRoutes = {
  notFound: {
    path: "*",
    Element: NotFoundPage,
  },
  verifyEmail: {
    path: "verify-email",
    Element: VerifyEmailPage,
  },
  checkSMS: {
    path: "check-sms/",
    Element: CheckSMSPage,
  },
  forbidden: {
    path: "/forbidden",
    Element: ForbiddenPage,
  },
};
