import { useUser } from "shared/hooks/_user/useUser";
import { DesktopNavbar } from "./_desktop/Desktop";
import { desktopConfig, desktopProjectConfig } from "./_desktop/desktopConfig";
import { MobileNavbar } from "./_mobile/Mobile";
import { mobileConfig, mobileProjectConfig } from "./_mobile/mobileConfig";

export function NavbarSimple() {
  const isProjectRole = useUser().role?.includes("project");
  return (
    <>
      <DesktopNavbar config={isProjectRole ? desktopProjectConfig : desktopConfig} />
      <MobileNavbar config={isProjectRole ? mobileProjectConfig : mobileConfig} />
    </>
  );
}
