import { LazyMainPage } from "modules/investorRole/pages/MainPage/lazy";
import { AnyObject } from "shared/types/common";
import { Route } from "shared/types/routes";

export const mainRoute: AnyObject<Route> = {
  main: {
    Element: LazyMainPage,
    path: "main",
  },
};
