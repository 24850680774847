import { Box } from "@mantine/core";
import { ErrorFallback } from "components/_error/ErrorFallback";
import { Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Outlet, useLocation, useSearchParams } from "react-router-dom";
import { ROLES } from "shared/const/roles";
import { ROUTES } from "shared/const/ROUTES";
import { useNavigateTo } from "shared/hooks/useNavigateTo";
import { useScrollTopWindow } from "shared/hooks/useSсrollTopWindow";
import { useUser } from "shared/hooks/_user/useUser";
import { Progress } from "shared/ui/_loaders/Progress";
import { useSocketsNotifications } from "../../../shared/hooks/_sockets/useSocketsNotifications";
import { Footer } from "./Footer";
import styles from "./index.module.scss";
import { NavbarSimple } from "./Navbar";
// import { useSocketsNotifications } from "shared/_hooks/_sockets/useSocketsNotifications";

export const MainLayout = () => {
  const location = useLocation();
  const searchParams = useSearchParams();
  const user = useUser();

  useSocketsNotifications();

  useScrollTopWindow({
    dependencies: [location.pathname, searchParams],
    condition: !location?.state?.withoutScrollTop,
  });

  const path = user.role?.includes(ROLES.project) ? ROUTES.PROJECT.MAIN : ROUTES.INVESTOR.MAIN;

  useNavigateTo({
    path,
    dependencies: [],
    condition: location.pathname === "/",
  });

  return (
    <>
      <NavbarSimple />
      <Box component={"main"} className={styles.wrapper}>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <Suspense fallback={<Progress />}>
            <Outlet />
          </Suspense>
        </ErrorBoundary>
      </Box>
      <Footer />
    </>
  );
};
