import { notifications } from "@mantine/notifications";
import React from "react";
import { GetByIdParams } from "shared/api/projects";
import { ProjectStatuses } from "shared/types/projects";
import { useProjects } from "stores/useProjects";
import { useSessionID } from "../_auth/useSessionID";

type Props = {
  id?: string;
  withoutEffect?: boolean;
  status?: ProjectStatuses;
};

const defaultProps = {};

export const useProject = ({ id, withoutEffect, status }: Props = defaultProps) => {
  const [isRequested, setIsRequested] = React.useState(false);
  const {
    data,
    fetchGet: { fx, pending, error },
    reset,
  } = useProjects(({ project }) => project);
  const sessionID = useSessionID()!;
  const notification = {
    title: "Возникла ошибка",
    message: error,
    color: "red",
  };
  const requestCondition = !pending && id && !withoutEffect;
  const offer = data?.activeRound?.activeOffer || null;

  React.useEffect(() => {
    if (requestCondition) {
      const requestProps: GetByIdParams = {
        params: { sessionID: sessionID!, id, status },
      };
      fx(requestProps);
      setIsRequested(true);
    }
  }, []);

  React.useEffect(() => {
    if (error) notifications.show(notification);
  }, [error]);

  return { data, pending, error, offer, reset, isRequested };
};
