import { Stack } from "@mantine/core";
import { Checkbox as CheckboxComp } from "react-hook-form-mantine";
import { Data } from "shared/types/forms";
import styles from "./index.module.scss";

type Props = {
  name: string;
  data: Data[];
  label?: string;
  required?: boolean;
  control: any;
  setValue: any;
};
const rules = (required?: boolean) => ({
  required: required
    ? {
        value: true,
        message: "Веберите один или несколько вариантов",
      }
    : {},
});

export const Checkbox = ({ data, control, name, required, setValue, ...otherProps }: Props) => {
  return (
    <CheckboxComp.Group
      className={styles.wrapper}
      required={true}
      rules={rules(required) as any}
      control={control}
      name={name}
      {...otherProps}
    >
      <Stack>
        {data!.map(({ value, label }) => (
          <CheckboxComp.Item
            iconColor={"dark.9"}
            className={styles.item}
            label={label}
            value={value}
            radius={"sm"}
            key={value}
          />
        ))}
      </Stack>
    </CheckboxComp.Group>
  );
};
