import { MantineSize, Text } from "@mantine/core";
import { TextBody } from "shared/types/table";

export type TableTextProps = {
  body: TextBody;
  size: MantineSize | string | undefined;
};
export const TableText = ({ size, body }: TableTextProps) => {
  return <Text size={size}>{body.value}</Text>;
};
