import { Button, ButtonVariant } from "@mantine/core";
import { useTimer } from "shared/hooks/useTimer";
import { Sizes } from "shared/types/mantine";

type Props = {
  text: string;
  startTime: number;
  error: string | null;
  variant?: ButtonVariant;
  onClick?: () => void;
  loading?: boolean;
  size?: Sizes;
  disabled?: boolean;
};

export const TimerButton = ({
  startTime,
  variant,
  onClick: onCLick,
  text,
  loading,
  size,
  disabled,
}: Props) => {
  const [restOfTime, handleRestart] = useTimer(startTime);
  const minutes = Math.floor(restOfTime / 60);
  const seconds = restOfTime % 60;
  const tablo = (number: number) => (number < 10 ? "0" + number : number);
  const timer = restOfTime ? `: ${tablo(minutes)}:${tablo(seconds)}` : null;

  const handleClick = async () => {
    onCLick && (await onCLick());
    handleRestart();
  };

  return (
    <Button
      loading={loading}
      onClick={handleClick}
      variant={variant}
      size={size ? size : "lg"}
      disabled={!!restOfTime || disabled}
    >
      {text}
      {timer}
    </Button>
  );
};
