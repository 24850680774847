import { Deal } from "shared/types/deals";
import { SliceModern } from "stores/_helpers/factories/createSliceNew";
import { createWithEqualityFn } from "zustand/traditional";
import { CreateDealFxs, dealSlice } from "./slices/dealSlice";
import { CreateDealsFxs, dealsSlice } from "./slices/dealsSlice";

type UseInvestment = {
  deal: SliceModern<Deal, CreateDealFxs>;
  deals: SliceModern<Deal[], CreateDealsFxs>;
};

export const useDeals = createWithEqualityFn<UseInvestment>((set, get) => ({
  deal: dealSlice(set, get),
  deals: dealsSlice(set, get),
}));
