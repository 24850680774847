import { ModernForm } from "shared/types/forms";
import { z } from "zod";

type ButtonConfig = {
  name: string;
  variant: "filled" | "default";
};
type Config = {
  buttonConfig: ButtonConfig[];
  form: ModernForm;
};

const ERROR_REQUIRED = "Поле обязательно для заполнения";

const TEAM_MEMBERS_NAMES = {
  firstName: "firstName",
  lastName: "lastName",
  photo: "photo",
  id: "id",
  project: "project",
  position: "position",
};

export const config: Config = {
  buttonConfig: [
    { name: "Добавить члена команды", variant: "filled" },
    { name: "Скрыть", variant: "default" },
  ],
  form: [
    [
      {
        type: "text",
        name: TEAM_MEMBERS_NAMES.firstName,
        label: "Имя",
        placeholder: "Иван",
        required: true,
      },
      {
        type: "text",
        name: TEAM_MEMBERS_NAMES.lastName,
        label: "Фамилия",
        placeholder: "Иванов",
        required: true,
      },
    ],
    {
      type: "text",
      name: TEAM_MEMBERS_NAMES.position,
      label: "Должность",
      placeholder: "CEO",
      required: true,
    },
    {
      type: "image",
      name: TEAM_MEMBERS_NAMES.photo,
      label: "Аватар",
      required: true,
    },
  ],
};

export const teamMemberSchema = z.object({
  firstName: z.string().min(1, ERROR_REQUIRED),
  lastName: z.string().min(1, ERROR_REQUIRED),
  position: z.string().min(1, ERROR_REQUIRED),
  photo: z.string().min(1, ERROR_REQUIRED),
});
