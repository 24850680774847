import { Fx } from "../../../shared/types/api";
import { storeResetFns } from "./resetStore";

type Props<Payload, GetProps, CreateProps, SetProps> = {
  key: string;
  getFx?: Fx<GetProps, Payload, Error>;
  createFx?: Fx<CreateProps, Payload, Error>;
  setFx?: Fx<SetProps, Payload, Error>;
};

type Fetch<Props> = {
  fx: (props: Props) => void;
  pending: boolean;
  error: null | string;
};

export type SliceModern<Payload, GetProps, CreateProps, SetProps> = {
  data: null | Payload;
  set: (data: any) => void;
  reset: () => void;
  fetchGet?: Fetch<GetProps>;
  fetchCreate?: Fetch<CreateProps>;
  fetchSet?: Fetch<SetProps>;
};

export type CreateSliceModern<Payload, GetProps, CreateProps, SetProps> = (
  set: any,
  get: any,
) => SliceModern<Payload, GetProps, CreateProps, SetProps>;

export function createSliceModern<Payload, GetProps, CreateProps, SetProps>({
  key,
  getFx,
  createFx,
  setFx,
}: Props<Payload, GetProps, CreateProps, SetProps>) {
  const fetch = <Props>(fx: Fx<Props, Payload, Error>, get: any, keyFx: string): Fetch<Props> => {
    return {
      fx: async (props: Props) => {
        const { set, reset } = (get() as any)[key];
        const fetch = () => (get() as any)[key][keyFx];
        reset();
        set({ [keyFx]: { ...fetch(), pending: true, error: null } });
        try {
          const data = await fx(props);
          set({ data });
        } catch (err: any) {
          const error = err.response.data.msg;
          set({ [keyFx]: { ...fetch(), error } });
        } finally {
          set({ [keyFx]: { ...fetch(), pending: false } });
        }
      },
      pending: false,
      error: null,
    };
  };

  const createFetch = <Props>(
    fx: Fx<Props, Payload, Error> | undefined,
    get: any,
    keyFx: string,
  ) => (fx ? fetch<Props>(fx, get, keyFx) : undefined);

  let slice: CreateSliceModern<Payload, GetProps, CreateProps, SetProps> = (set, get) => {
    const reset = () => {
      const data = (get() as any)[key];
      set({ [key]: { ...data, data: null } } as any);
    };

    storeResetFns.add(reset);

    return {
      data: null,
      set: (props) => {
        const data = (get() as any)[key];
        set({ [key]: { ...data, ...props } } as any);
      },
      reset: reset,
      fetchGet: createFetch<GetProps>(getFx, get, "fetchGet"),
      fetchCreate: createFetch<CreateProps>(createFx, get, "fetchCreate"),
      fetchSet: createFetch<SetProps>(setFx, get, "fetchSet"),
    };
  };

  return slice;
}
