import {
  ERROR_MESSAGE_PIN_LENGTH,
  SMS_CODE_LENGTH,
} from "pages/_service/CheckSMSPage/components/CheckSMSRouter/variants/CheckSMSCheck/CheckSMSForm/const";
import { z } from "zod";
export const checkSMSFormValidation = z.object({
  smsCodeInvestor: z
    .string()
    .min(SMS_CODE_LENGTH, ERROR_MESSAGE_PIN_LENGTH)
    .max(SMS_CODE_LENGTH, ERROR_MESSAGE_PIN_LENGTH),
});
