import { MantineSize } from "@mantine/core";
import { Sizes } from "shared/types/mantine";
import { StatusBody } from "shared/types/table";
import { BadgeStatus } from "shared/ui/BadgeStatus";

export type TableStatusProps = {
  body: StatusBody;
  size: MantineSize | string | undefined;
};
export const TableStatus = ({ size, body }: TableStatusProps) => {
  return <BadgeStatus rules={body.variants} status={body.value} size={size as Sizes} />;
};
