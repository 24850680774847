import { ErrorFallback } from "components/_error/ErrorFallback";
import { createBrowserRouter, createRoutesFromElements } from "react-router-dom";
import { AuthRouter } from "./routers/AuthRouter/router";
import { PrivateRouter } from "./routers/PrivateRouter/router";
import { ServiceRouter } from "./routers/ServiceRouter/router";

export const router = createBrowserRouter([
  {
    errorElement: <ErrorFallback />,
    children: createRoutesFromElements([PrivateRouter(), AuthRouter(), ServiceRouter()]),
  },
]);
