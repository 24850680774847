export const REQUISITES = {
  general: {
    name: {
      name: "Название",
      value: 'ООО "Инвестра"',
    },
    inn: {
      name: "ИНН",
      value: "2312310925",
    },
    kpp: {
      name: "КПП",
      value: "231201001",
    },
    account: {
      name: "Номер счета",
      value: "40702810510001556311",
    },
    ogrn: {
      name: "ОГРН",
      value: "1222300032470",
    },
    bankName: {
      name: "Название банка",
      value: "АО «Тинькофф Банк»",
    },
    bankInn: {
      name: "ИНН Банка",
      value: "7710140679",
    },
    biq: {
      name: "БИК",
      value: "044525974",
    },
    corrAccount: {
      name: "Корр. Счет банка",
      value: "30101810145250000974",
    },
    legalAddress: {
      name: "Юридический адрес",
      value: "350061, Краснодарский край, г Краснодар, Парусная ул, д. 10 к. 1, помещ. 12 ком. 2",
    },
  },
  getValue: () =>
    Object.keys(REQUISITES.general).map((key) => ({
      [key]: (REQUISITES.general as any)[key].value,
    })),
  getArr: () =>
    Object.keys(REQUISITES.general).map((key) => ({
      name: (REQUISITES.general as any)[key].name,
      value: (REQUISITES.general as any)[key].value,
    })),
};
