import {
  IconBuildingStore,
  IconCircle,
  IconCoins,
  IconDots,
  IconFileDescription,
  IconHeartHandshake,
  IconHome,
  IconLogout,
  IconMessageQuestion,
  IconNews,
  IconRocket,
  IconSettings,
  IconUserCircle,
  IconWallet,
} from "@tabler/icons-react";
import { NavbarData } from "shared/types/navbar";

export const mobileConfig: NavbarData = [
  {
    type: "link",
    link: "main",
    label: "Главная",
    icon: IconHome,
  },
  {
    type: "link",
    link: "accounts",
    param: "individual",
    label: "Счета",
    icon: IconWallet,
  },
  { type: "link", label: "Инвестировать", icon: IconRocket, link: "deals/create", main: true },
  {
    type: "link",
    link: "projects",
    label: "Проекты",
    icon: IconBuildingStore,
  },
  {
    type: "menu",
    label: "Меню",
    icon: IconDots,
    children: [
      { type: "link", link: "deals", label: "Сделки", icon: IconCoins },
      {
        type: "link",
        link: "/news",
        label: "Новости",
        icon: IconNews,
      },
      {
        type: "link",
        link: "profile",
        label: "Профиль",
        param: "all",
        icon: IconUserCircle,
      },
      {
        type: "link",
        link: "/docs",
        label: "Документы",
        icon: IconFileDescription,
      },
      {
        type: "link",
        link: "/faq",
        label: "FAQ",
        icon: IconMessageQuestion,
      },
      {
        type: "link",
        link: "/support",
        label: "Поддержка",
        icon: IconHeartHandshake,
      },
      {
        type: "button",
        label: "Выйти",
        action: "logout",
        icon: IconLogout,
      },
    ],
  },
];

export const mobileProjectConfig: NavbarData = [
  {
    type: "link",
    link: "/project/main",
    label: "Главная",
    icon: IconHome,
  },
  {
    type: "link",
    link: "/project/CardSettings/settings/common",
    label: "Настройка проекта",
    icon: IconSettings,
  },
  {
    type: "link",
    link: "/project/rounds",
    label: "Раунды",
    icon: IconCircle,
  },
  {
    type: "link",
    link: "/project/profile",
    label: "Профиль",
    icon: IconUserCircle,
    param: "all",
  },
  {
    type: "menu",
    label: "Меню",
    icon: IconDots,
    children: [
      {
        type: "link",
        link: "/docs",
        label: "Документы",
        icon: IconFileDescription,
      },
      {
        type: "link",
        link: "/faq",
        label: "FAQ",
        icon: IconMessageQuestion,
      },

      {
        type: "link",
        link: "/support",
        label: "Поддержка",
        icon: IconHeartHandshake,
      },
      {
        type: "button",
        label: "Выйти",
        action: "logout",
        icon: IconLogout,
      },
    ],
  },
];
