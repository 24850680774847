import { Token } from "shared/types/user";
import { persist } from "zustand/middleware";
import { createWithEqualityFn } from "zustand/traditional";

type User = {
  sessionID: null | string;
  userId: null | string;
  role: null | string;
};
type UseAuthNew = {
  setAuth: (user: Token) => void;
  resetAuth: () => void;
} & User;

export const useAuth = createWithEqualityFn(
  persist<UseAuthNew>(
    (set) => ({
      sessionID: null,
      userId: null,
      role: null,
      setAuth: (user) => set({ ...(user as any) }),
      resetAuth: () => set({ userId: null, sessionID: null, role: null }),
    }),
    { name: "token" },
  ),
);
