import { Box, FileInput as FileInputMantine, ScrollArea, Stack } from "@mantine/core";
import _ from "lodash";
import React from "react";
import { Controller, UseFormSetValue, useWatch } from "react-hook-form";
import { useUploadFiles } from "shared/hooks/useUploadFiles";
import { FileInput as FIleInputType } from "shared/types/forms";
import { Sizes } from "shared/types/mantine";
import { FilePills } from "shared/ui/_forms/_common/_inputs/FileInput/components/FilePills";
import { ImageList } from "shared/ui/_forms/_common/_inputs/FileInput/components/ImageList";
import { PopoverInfoIcon } from "shared/ui/_forms/_common/_inputs/_components/TooltipInfoIcon";
import { Loader } from "shared/ui/_loaders/Loader";
import { LabelInput } from "../_components/LabelInput";

const FileInputMantineWithPlaceholder = FileInputMantine as any;

type Props = FIleInputType & {
  size?: Sizes;
  control: any;
  setValue: UseFormSetValue<any>;
  isImage?: boolean;
};

export const FileInput = ({
  control,
  setValue,
  name,
  required,
  size,
  error,
  label,
  multiple,
  isImage,
  tooltip,
  ...props
}: Props) => {
  const uploadFiles = useUploadFiles();
  const [file, setFile] = React.useState<File | File[] | null>(null);
  const value = useWatch({ control, name });
  const placeholderVariants = [
    "Нажмите на поле, чтобы добавить файл",
    "Нажмите на поле, чтобы добавить файл (Можно несколько)",
    props.placeholder,
  ];
  const placeholderIndex = props.placeholder ? 2 : multiple ? 1 : 0;
  const placeholder = placeholderVariants[placeholderIndex];

  React.useEffect(() => {
    if (!uploadFiles.fileLinks.length || _.isEqual(value, uploadFiles.fileLinks)) return;

    setValue(name, multiple ? uploadFiles.fileLinks : uploadFiles.fileLinks.join(""));
  }, [uploadFiles.fileLinks.length]);

  React.useEffect(() => {
    if (!value || _.isEqual(value, uploadFiles.fileLinks)) return;

    uploadFiles.handlers.setState(multiple ? value : value.split(","));
  }, [value]);

  const resetFile = () => setFile(null);

  React.useEffect(() => {
    if (file) {
      if (!multiple) {
        uploadFiles.handleUploadFile(file as File);
        resetFile();
      } else {
        uploadFiles.handleUploadFiles(file as File[]);
        resetFile();
      }
    }
  }, [file]);

  const handleRemoveFile = (index: number) => {
    uploadFiles.handlers.remove(index);
    setValue(name, "");
  };

  const rightSection = uploadFiles.pending ? (
    <Loader loading={uploadFiles.pending} />
  ) : (
    <PopoverInfoIcon label={tooltip} visible={Boolean(tooltip)} />
  );

  return (
    <Box>
      <LabelInput label={label} required={required} size={size} />
      <Stack gap={"md"}>
        <Controller
          name={name}
          control={control}
          render={() => (
            <FileInputMantineWithPlaceholder
              placeholder={placeholder || "Нажмите на поле, чтобы добавить файл"}
              {...props}
              value={file}
              disabled={uploadFiles.pending}
              rightSection={rightSection}
              onChange={setFile}
              multiple={multiple}
              size={size}
              accept={isImage && ".jpg, .jpeg, .png, webp,"}
              error={error}
              width={"inherit"}
            />
          )}
        />
        <ScrollArea scrollbars={"y"} mah={400}>
          {isImage ? (
            <ImageList fileLinks={uploadFiles.fileLinks} handleRemoveFile={handleRemoveFile} />
          ) : (
            <FilePills
              fileLinks={uploadFiles.fileLinks}
              size={size}
              handleRemoveFile={handleRemoveFile}
            />
          )}
        </ScrollArea>
      </Stack>
    </Box>
  );
};
