import { Stack } from "@mantine/core";
import React from "react";
import { Form as FormComp } from "react-hook-form";
import { Sizes } from "../../types/mantine";
import { ErrorText } from "../_typography/text/ErrorText";

type Props = {
  children: React.ReactNode | React.ReactNode[];
  onSubmit?: React.FormEventHandler<HTMLFormElement>;
  gap?: Sizes;
  error?: string | null;
  control?: any;
};

export const Form = ({ onSubmit, gap, children, error, control }: Props) => {
  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    onSubmit && onSubmit(e);
  };

  return (
    <FormComp control={control} onSubmit={handleSubmit as any}>
      <Stack gap={gap && gap}>{children}</Stack>
      <ErrorText error={error} />
    </FormComp>
  );
};
