import { ModernForm } from "../../types/forms";

export const quizForm: ModernForm = [
  {
    type: "radio",
    name: "investmentExperience",
    required: true,
    label: "Насколько вы опытный инвестор",
    data: [
      {
        value: "Начинающий",
        label: "Начинающий",
      },
      {
        value: "Любитель",
        label: "Любитель",
      },
      {
        value: "Профессионал",
        label: "Профессионал",
      },
    ],
  },
  {
    type: "checkbox",
    name: "moneySource",
    required: true,
    label: "Информация об источниках происхождения денежных средств/имущества",
    data: [
      {
        value: "Доход по основному месту работы",
        label: "Доход по основному месту работы",
      },
      {
        value: "Доход от работы по совместительству",
        label: "Доход от работы по совместительству",
      },
      {
        value: "Доход от вкладов, открытых на мое имя",
        label: "Доход от вкладов, открытых на мое имя",
      },
      {
        value:
          "Доходы от совершения сделок с ценными бумагами и производными финансовыми инструментами",
        label:
          "Доходы от совершения сделок с ценными бумагами и производными финансовыми инструментами",
      },
      {
        value: "Дивиденды от участия в коммерческих организациях",
        label: "Дивиденды от участия в коммерческих организациях",
      },
      { value: "Накопления прошлых лет", label: "Накопления прошлых лет" },
      {
        value: "Кредитные, заемные средства",
        label: "Кредитные, заемные средства",
      },
      { value: "Наследство", label: "Наследство" },
      { value: "Пенсия", label: "Пенсия" },
      { value: "Другое", label: "Другое" },
    ],
  },
  {
    type: "checkbox",
    name: "investmentTarget",
    required: true,
    label: "Какая Ваша инвестиционная цель?",
    data: [
      {
        label: "Регулярный пассивный доход",
        value: "Регулярный пассивный доход",
      },
      {
        label: "Сохранение денег",
        value: "Сохранение денег",
      },
      {
        label: "Увеличение капитала",
        value: "Увеличение капитала",
      },
    ],
  },
  {
    type: "radio",
    name: "riskLevel",
    required: true,
    label: "Какой уровень соотношения риска и доходности для Вас предпочтителен?",
    data: [
      {
        value: "Низкий риск с доходностью до 10% годовых",
        label: "Низкий риск с доходностью до 10% годовых",
      },
      {
        value: "Средний риск с доходностью до 10-15% годовых",
        label: "Средний риск с доходностью до 10-15% годовых",
      },
      {
        value: "Высокий риск с доходностью до 15-20% годовых",
        label: "Высокий риск с доходностью до 15-20% годовых",
      },
    ],
  },
  {
    type: "radio",
    name: "investmentPortfolio",
    required: true,
    label: "Какой Ваш текущий размер инвестиционного портфеля?",
    data: [
      {
        value: "До 1 млн руб.",
        label: "До 1 млн руб.",
      },
      {
        value: "1-10 млн руб.",
        label: "1-10 млн руб.",
      },
      {
        value: "10-100 млн руб.",
        label: "10-100 млн руб.",
      },
      {
        value: ">100 млн руб.",
        label: ">100 млн руб.",
      },
      {
        value: "Предпочитаю не указывать",
        label: "Предпочитаю не указывать",
      },
    ],
  },
  {
    type: "checkbox",
    name: "typeOfAssets",
    required: true,
    label: "Какие виды активов/инструментов Вы используете?",
    data: [
      {
        label: "Банковский депозит",
        value: "Банковский депозит",
      },
      {
        label: "Акции",
        value: "Акции",
      },
      {
        label: "Облигации",
        value: "Облигации",
      },
      {
        label: "Жилая недвижимость (квартиры)",
        value: "Жилая недвижимость (квартиры)",
      },
      {
        label: "Коммерческая недвижимость",
        value: "Коммерческая недвижимость",
      },
      {
        label: "Другое",
        value: "Другое",
      },
    ],
  },
];
