import { Anchor, Group, Pill } from "@mantine/core";
import { Link } from "react-router-dom";
import { Sizes } from "shared/types/mantine";

type Props = {
  fileLinks: string[];
  size?: Sizes;
  handleRemoveFile: (index: number) => void;
};
export const FilePills = ({ fileLinks, size, handleRemoveFile }: Props) => {
  const pills = fileLinks?.map((photoLink, index) => (
    <Pill
      bg={"dark.5"}
      size={size}
      key={index}
      withRemoveButton
      removeButtonProps={{ onClick: () => handleRemoveFile(index) }}
    >
      <Anchor component={Link} size={size} to={photoLink} target={"_blank"}>
        Файл {index + 1}
      </Anchor>
    </Pill>
  ));

  return <Group gap={size}>{pills}</Group>;
};
