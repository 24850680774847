import { Box, Button, Center, Image, Overlay, Paper, Text } from "@mantine/core";
import React from "react";
import { useWatch } from "react-hook-form";
import { FileParams, uploadFileFX } from "shared/api/files";
import { useFetch } from "shared/hooks/useFetch";
import { useSessionID } from "shared/hooks/_auth/useSessionID";
import { ImageInput as ImageInputType } from "shared/types/forms";
import { FileButton } from "shared/ui/_buttons/FileButton";
import { ErrorText } from "shared/ui/_typography/text/ErrorText";
import styles from "./index.module.scss";

type Props = ImageInputType & {
  size?: string;
  control: any;
  setValue: (name: string, value: unknown, config?: Object) => void;
  type: never;
};

const buttonConfigs = [
  {
    className: "",
    title: "Загрузить",
  },
  {
    className: styles.button,
    title: "Изменить",
  },
];

export const ImageInput = ({
  size = "sm",
  control,
  setValue,
  label,
  name,
  required,
  error,
}: Props) => {
  const [file, setFile] = React.useState<File | null>(null);
  const uploadFileFx = useFetch<FileParams, string>(uploadFileFX);
  const sessionID = useSessionID()!;
  const photoLink = useWatch({ control, name });
  const buttonConfigIndex = Number(!!photoLink && !uploadFileFx.pending);
  const buttonConfig = buttonConfigs[buttonConfigIndex];

  React.useEffect(() => {
    if (file) {
      uploadFileFx.fetchData({ file, sessionID });
      setFile(null);
    }
  }, [file]);

  React.useEffect(() => {
    if (uploadFileFx.data) {
      setValue(name, uploadFileFx.data, { required });
    }
  }, [uploadFileFx.data]);

  const requiredStar = required && (
    <Text span className={styles.requiredButton} c={"error"}>
      *
    </Text>
  );

  const photoImage = photoLink && (
    <Image pos={"absolute"} h={150} w={150} src={photoLink} top={0} left={0} />
  );

  const fileButton = (
    <Center h={"100%"}>
      <FileButton onChange={setFile} accept="image/png,image/jpeg">
        {(props) => (
          <Button
            className={buttonConfig.className}
            loading={uploadFileFx.pending}
            variant="default"
            {...props}
          >
            {buttonConfig.title}
          </Button>
        )}
      </FileButton>
    </Center>
  );

  return (
    <Box mt={"xs"}>
      <Text fw={500} size={size}>
        {label} {requiredStar}
      </Text>
      <Paper className={styles.imageWrapper} pos={"relative"} h={150} w={150} bg="dark.6">
        {photoImage}
        <Overlay className={styles.overlay} color="dark.9" zIndex={0} />
        {fileButton}
      </Paper>
      <ErrorText error={error}></ErrorText>
    </Box>
  );
};
