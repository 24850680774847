import React from "react";

export const useInfinityScroll = (
  dependence: any[] | null,
  page: number,
  setPage: any,
): [React.RefObject<HTMLDivElement>, React.RefObject<HTMLDivElement[]>] => {
  const scrollableRef = React.useRef<HTMLDivElement>(null);
  const targetsRef = React.useRef<HTMLDivElement[]>([]);

  React.useEffect(() => {
    if (dependence) {
      const currentIndex = 30 * (page + 1) - 10;
      const options = {
        root: scrollableRef.current,
        rootMargin: "0px",
        threshold: 1.0,
      };
      if (dependence.length > currentIndex) {
        const callback: IntersectionObserverCallback = (entries, observer) => {
          if (entries[0].isIntersecting) {
            setPage((page: number) => ++page);
            observer.disconnect();
          }
        };
        const observer = new IntersectionObserver(callback, options);
        targetsRef.current.length && observer.observe(targetsRef.current[currentIndex]);

        return () => {
          observer.disconnect();
        };
      }
    }
  }, [dependence]);

  return [scrollableRef, targetsRef];
};
