import { AspectRatio, Box, Center, Loader, Paper, Stack, Title } from "@mantine/core";
import ProjectAboutTeam from "modules/investorRole/pages/ProjectsPage/ProjectPage/pages/ProjectAboutPage/components/ProjectAboutTeam";
import ProjectAboutGallery from "modules/investorRole/pages/ProjectsPage/ProjectPage/pages/ProjectAboutPage/components/ProjectAmoutGallery";
import sanitizeHtml from "sanitize-html";
import { getIframeYoutubeLink } from "shared/helpers/getIframeYoutubeLink";
import { useProject } from "shared/hooks/_projects/useProject";

const ProjectAboutPage = () => {
  const { data: project, pending } = useProject({ withoutEffect: true });

  if (pending)
    return (
      <Paper>
        <Center>
          <Loader />
        </Center>
      </Paper>
    );

  const video = project?.video && (
    <Paper p={0} style={{ overflow: "hidden" }}>
      <AspectRatio ratio={16 / 9}>
        <iframe
          title={"project-video"}
          src={getIframeYoutubeLink(project.video)}
          style={{ border: 0 }}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </AspectRatio>
    </Paper>
  );

  return (
    <Stack gap={"lg"} w={"100%"}>
      {video || <ProjectAboutGallery />}
      {video ? <ProjectAboutGallery /> : null}
      <ProjectAboutTeam />
      <Paper component={Stack} gap={"lg"}>
        <Title order={4}>О проекте</Title>
        <Box
          style={{ overflow: "hidden" }}
          dangerouslySetInnerHTML={{ __html: sanitizeHtml(project?.aboutProject!) }}
        />
      </Paper>
    </Stack>
  );
};

export default ProjectAboutPage;
