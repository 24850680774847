import cx from "clsx";
import React, { FC } from "react";
import { ColumnAndRowProps, ItemProps } from "shared/types/bootstrapGrid";
import styles from "./index.module.scss";

const Column: FC<ColumnAndRowProps> = ({
  className: classname,
  children,
  component: Component = "div",
  ...props
}) => {
  const className = cx(styles.column, classname);
  if (typeof Component === "string") {
    return React.createElement(Component, { className, ...props }, children);
  }
  return (
    <Component className={className} {...props}>
      {children}
    </Component>
  );
};

const Row: FC<ColumnAndRowProps> = ({
  className: classname = "",
  component: Component = "div",
  scrollable = false,
  scrollableFrom = null,
  vertical = false,
  children,
  ...props
}) => {
  const className = cx(
    styles.row,
    { [styles.mdScrollable]: scrollable },
    { [styles[`${scrollableFrom}Scrollable`]]: scrollableFrom },
    { [styles.vertical]: vertical },
    classname,
  );

  if (typeof Component === "string") {
    return React.createElement(Component, { className, ...props }, children);
  }
  return (
    <Component className={className} {...props}>
      {children}
    </Component>
  );
};

const Item: FC<ItemProps> = ({
  component: Component = "div",
  col = 12,
  colSize: size,
  h = "auto",
  without,
  withoutSpacing = false,
  scrollable = false,
  className: classname,
  children,
  grow = false,
  ...props
}) => {
  const className = cx(
    styles[`col${col}`],
    { [styles.mdScrollable]: scrollable },
    { [styles[`col${col}${size}`]]: size },
    { [styles[`without${without}`]]: without },
    { [styles.withoutSpacing]: withoutSpacing },
    { [styles.grow]: grow },
    classname,
  );

  if (typeof Component === "string") {
    return React.createElement(Component, { className, ...props }, children);
  }
  return (
    <Component className={className} {...props}>
      {children}
    </Component>
  );
};

type RowWithItemProps = {
  children: React.ReactNode;
  rowProps?: Omit<ColumnAndRowProps, "children">;
  itemProps?: ItemProps;
};

const RowWithItem: FC<RowWithItemProps> = ({ children, rowProps, itemProps }) => {
  return (
    <Row {...rowProps}>
      <Item {...itemProps}>{children}</Item>
    </Row>
  );
};

const BootstrapGrid = {
  Item,
  Row,
  Column,
  RowWithItem,
};

export default BootstrapGrid;
