import {
  Badge,
  Button,
  Checkbox,
  Container,
  createTheme,
  CSSVariablesResolver,
  Drawer,
  Flex,
  Group,
  Paper,
  Radio,
  rem,
  Skeleton,
  Stack,
  Stepper,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { Notifications } from "@mantine/notifications";
import { IconCheck } from "@tabler/icons-react";
const CONTAINER_SIZES: Record<string, string> = {
  xxs: rem(300),
  xs: rem(400),
  sm: rem(500),
  md: rem(600),
  lg: rem(700),
  xl: rem(800),
  xxl: rem(1100),
};

export const theme = createTheme({
  primaryColor: "gold",
  colors: {
    gold: [
      "#fffce1",
      "#fff8cc",
      "#ffef9b",
      "#ffe764",
      "#ffdf38",
      "#ffdb1c",
      "#ffd809",
      "#e3bf00",
      "#c9aa00",
      "#ae9200",
    ],
    dark: [
      "#c1c2c5",
      "#a6a7ab",
      "#909296",
      "#5c5f66",
      "#373a40",
      "#2c2e33",
      "#25262b",
      "#1a1b1e",
      "#141517",
      "#101113",
    ],
    error: [
      "#ffe9e9",
      "#ffd4d4",
      "#f4a8a8",
      "#ec7879",
      "#e55050",
      "#e13636",
      "#e02728",
      "#c61a1d",
      "#b21217",
      "#9c0412",
    ],
  },

  fontFamily: "Inter",
  defaultRadius: "md",

  components: {
    Paper: Paper.extend({
      defaultProps: {
        radius: "lg",
        bg: "var(--mantine-color-dark-7)",
      },
      styles: {
        root: {
          padding: "var(--mantine-spacing-lg)",
        },
      },
    }),
    DatePickerInput: DatePickerInput.extend({
      styles: {
        label: {
          paddingBottom: "0.5em",
        },
      },
    }),
    Skeleton: Skeleton.extend({
      defaultProps: {
        radius: "lg",
      },
    }),
    Stack: Stack.extend({
      defaultProps: {
        gap: "lg",
      },
    }),
    Group: Group.extend({
      defaultProps: {
        gap: "lg",
      },
    }),
    Checkbox: Checkbox.extend({
      defaultProps: {
        iconColor: "dark.9",
      },
      styles: {
        root: {
          cursor: "pointer",
        },
        body: {
          alignItems: "center",
        },
      },
    }),
    Stepper: Stepper.extend({
      defaultProps: {
        completedIcon: <IconCheck color={"black"} />,
      },
    }),
    Radio: Radio.extend({
      defaultProps: {
        iconColor: "dark.9",
      },
      styles: {
        body: {
          alignItems: "center",
        },
      },
    }),
    Flex: Flex.extend({
      defaultProps: {
        gap: "lg",
      },
    }),
    Drawer: Drawer.extend({
      defaultProps: {
        position: "right",
      },
      styles: {
        content: {
          padding: 0,
        },
      },
    }),
    Badge: Badge.extend({
      defaultProps: {
        c: "dark",
      },
    }),
    Container: Container.extend({
      vars: (_, { size, fluid }) => ({
        root: {
          "--container-size": fluid
            ? "100%"
            : size !== undefined && size in CONTAINER_SIZES
            ? CONTAINER_SIZES[size]
            : rem(size),
        },
      }),
    }),
    Button: Button.extend({
      defaultProps: {
        variant: "filled",
      },
    }),
  },

  other: {
    beige: "#EBDFD5",
    brown: "#42221A",
    beigeLight: "#DAD0A7",
    typography: {
      success: "#1E5945",
    },
    margin: {
      main: "2rem",
      min: "1rem",
    },
  },
});

export const resolver: CSSVariablesResolver = (theme) => ({
  variables: {
    "--mantine-color-beige": theme.other.beige,
    "--mantine-color-beige-light": theme.other.beigeLight,
    "--mantine-color-brown": theme.other.brown,
    "--mantine-margin-element": "1.5rem",
    "--mantine-margin-main": theme.other.margin.main,
    "--mantine-margin-min": theme.other.margin.min,
    "--mantine-header-height": "4rem",
    "--mantine-footer-height": "3.5rem",
  },
  light: {},
  dark: {},
});

export const mobileTheme = createTheme({
  components: {
    ...theme.components,
    Notifications: Notifications.extend({
      defaultProps: {
        position: "top-center",
      },
    }),
  },
});
