import { useOffersStore } from "modules/investorRole/stores/offers/useOffers";

import { OFFER_STATUSES } from "shared/const/offers";
import { createGetItemsHook } from "shared/hooks/helpers/createGetItemsHook";

export const useProjectOffers = (payload: {
  params: { projectId: string; status?: (keyof typeof OFFER_STATUSES)[] };
  withoutAutoRequest?: boolean;
}) =>
  createGetItemsHook({
    useStore: useOffersStore,
    fetchKey: "fetchGetByProject",
    customParams: payload.params,
  })({ withoutAutoRequest: payload.withoutAutoRequest });
