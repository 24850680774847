import React from "react";
import { offersFx } from "shared/api/_project/rounds/offers";
import { useFetch } from "shared/hooks/useFetch";
import { useSessionID } from "shared/hooks/_auth/useSessionID";

export const useActualProjectOffer = (params: { projectId: string }) => {
  const sessionID = useSessionID();
  const { data, fetchData, pending } = useFetch(offersFx.getOffersByProjectId);

  React.useEffect(() => {
    fetchData({ ...params, sessionID, status: ["fundraising", "confirmed"], page: 0 });
  }, []);

  return { data, pending, sessionID };
};
