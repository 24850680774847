import { Carousel } from "@mantine/carousel";
import { Image, Paper, Stack, Title } from "@mantine/core";
import { useProjects } from "stores/useProjects";

const ProjectAboutGallery = () => {
  const project = useProjects(({ project: { data } }) => data);

  const carousel = project?.images.map((image) => (
    <Carousel.Slide h={400} key={image}>
      <Image h={400} src={image} fit={"cover"} />
    </Carousel.Slide>
  ));

  return (
    <Paper component={Stack} gap={"sm"}>
      <Title order={4}>Галерея</Title>
      <Paper p={0} style={{ overflow: "hidden" }}>
        <Carousel withIndicators>{carousel}</Carousel>
      </Paper>
    </Paper>
  );
};

export default ProjectAboutGallery;
