import { LazyCardSettingsPage } from "modules/projectRole/pages/CardSettingsPage/lazy";
import { ROUTES } from "shared/const/ROUTES";
import { AnyObject } from "shared/types/common";
import { Route } from "shared/types/routes";

export const projectRoleSettingsRoute: AnyObject<Route> = {
  projectSettings: {
    path: ROUTES.PROJECT.PROJECT_SETTINGS,
    Element: LazyCardSettingsPage,
  },
};
