export const MASKS = {
  mobilePhone: {
    mask: "+7 (%##) ###-##-##",
    definitions: {
      "%": /[9]/,
      "#": /[0-9]/,
    },
    placeholder: "+7 (999) 999-99-99",
  },
  orgPhone: {
    mask: "8 (###) ###-##-##",
    definitions: {
      "#": /[0-9]/,
    },
    placeholder: "8 (495) 999-99-99",
  },
};
