import { ActionIcon, MantineSize } from "@mantine/core";
import { ActionIconBody } from "shared/types/table";

export type TableActionIconProps = {
  body: ActionIconBody;
  size: MantineSize | string | undefined;
};
export const TableActionIcon = ({ size, body }: TableActionIconProps) => {
  return (
    <ActionIcon
      size={size}
      variant={"transparent"}
      color={"dark.0"}
      onClick={body.onClick}
      disabled={body.disabled}
    >
      {body.value}
    </ActionIcon>
  );
};
