import { createApi } from "shared/api/_factories/createApi";
import { Fx, SIDParams } from "shared/types/api";
import { TeamMember } from "shared/types/projects";

const structure = "teammembers";
const api = createApi({ structure });

type ProjectParam = { project: string };

export type TeamMembersGetByProjectParams = SIDParams & ProjectParam;

const getByProject: Fx<TeamMembersGetByProjectParams, TeamMember[], Error> = (params) =>
  api().getItems("getByProject", params);

type Ids = {
  ids: string;
};

export type TeamMembersGetByIdsParams = SIDParams & Ids;

const getByIds: Fx<TeamMembersGetByIdsParams, TeamMember[], Error> = (params) =>
  api().getItems("getByIds", params);

export type TeamMembersSetItemsProps = {
  params: SIDParams;
  body: TeamMember[];
};

const setItems: Fx<TeamMembersSetItemsProps, TeamMember[], Error> = ({ params, body }) =>
  api().setItems("set", body, params);

export const teamMembersFx = { getByProject, getByIds, setItems };
