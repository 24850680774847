import { useCardSettingsStore } from "modules/projectRole/stores/useCardSettings";
import React, { useEffect } from "react";
import { useSendError } from "shared/hooks/useSendError";
import { useSessionID } from "shared/hooks/_auth/useSessionID";
import { CardSettings } from "shared/types/projects";

export const useCardSettings = (withoutAutoRequest: boolean = false) => {
  const sessionID = useSessionID()!;
  const { data, fetchGetItem, fetchSetItem, fetchCheckItem } = useCardSettingsStore();
  const [error, setError] = React.useState<string | null>(null);
  const params = { sessionID };
  const commonPending = fetchGetItem.pending || fetchSetItem.pending || fetchCheckItem.pending;

  useEffect(() => {
    if (data || withoutAutoRequest) return;

    fetchGetItem.fx(params);
  }, []);

  useSendError(fetchSetItem.error || fetchGetItem.error || fetchCheckItem.error || error);

  const handleSetCardSettings = (cardSettings: CardSettings) => {
    fetchSetItem.fx({ params, data: cardSettings });
  };

  useEffect(() => {
    setError(null);
  }, [error]);

  const handleCheckItem = () => {
    if (!data) return;

    if (data.status !== "draft") {
      setError("Для отправки на проверку нужно что то изменить");
      return;
    }

    fetchCheckItem.fx({ params: { sessionID }, body: { id: data.id } });
  };

  return {
    data,
    fetchGetItem,
    commonPending,
    setItemFx: { ...fetchSetItem, handleSetCardSettings },
    fetchCheckItem: { ...fetchCheckItem, handleCheckItem },
  };
};
