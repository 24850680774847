import { notifications } from "@mantine/notifications";
import React from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { useSessionID } from "shared/hooks/_auth/useSessionID";
import { AnyObject } from "shared/types/common";
import { FxSlice } from "shared/types/store";
import { SliceModern } from "stores/_helpers/factories/createSliceNew";
import { StoreApi } from "zustand";
import { UseBoundStoreWithEqualityFn } from "zustand/traditional";
import { useInfinityScroll } from "../useInfinityScroll";

type Props<Payload, Params> = {
  useStore: UseBoundStoreWithEqualityFn<StoreApi<SliceModern<Payload, Params>>>;
  fetchKey?: string;
  searchParamsKeys?: string[];
  customParams?: any;
};

type HookProps = {
  withoutAutoRequest?: boolean;
  searchParams?: AnyObject<string | number | boolean>;
};

export const createGetItemsHook = <Payload, Params>({
  useStore,
  fetchKey = "fetchGetAll",
  searchParamsKeys,
  customParams = {},
}: Props<Payload, Params>) => {
  const useHook = ({ withoutAutoRequest, searchParams = {} }: HookProps = {}) => {
    const [page, setPage] = React.useState(0);
    const { data, reset, ...store } = useStore();
    const sessionID = useSessionID()!;
    const { pending, error, fx, fxAddData, fxWithoutPending } = (store as any)[
      fetchKey
    ] as FxSlice<Params>;
    const [searchParamsDefault, _] = useSearchParams();
    const params: { [p: string]: string } = React.useMemo(
      () =>
        searchParamsKeys?.reduce((reducer: any, key) => {
          if (searchParamsDefault.get(key)) {
            reducer[key] = searchParamsDefault.get(key);
          }
          return reducer;
        }, {}),
      [searchParamsDefault],
    );
    const fetchParams = { ...params, page, sessionID, ...customParams, ...searchParams } as Params;
    const [scrollableRef, targetsRef] = useInfinityScroll(data as any, page, setPage);
    const location = useLocation();
    const handleGetItems = () => {
      if (!pending) fx(fetchParams);
    };

    const handleUpdate = () => {
      fxWithoutPending(fetchParams);
    };

    const resetPage = () => {
      setPage(0);
    };

    React.useEffect(() => {
      if (!pending && !withoutAutoRequest) {
        resetPage();
        fx(fetchParams);
      }
    }, [params, withoutAutoRequest, location.pathname]);

    React.useEffect(() => {
      if (!pending && data && !withoutAutoRequest && page) fxAddData(fetchParams);
    }, [page]);

    React.useEffect(() => {
      const errorNotification = () => ({
        color: "red",
        title: "Произошла ошибка",
        message: error,
      });
      if (error) notifications.show(errorNotification());
    }, [error]);

    return {
      data,
      pending,
      error,
      scrollableRef,
      targetsRef,
      page,
      handleGetItems,
      reset,
      handleUpdate,
    };
  };
  return useHook;
};
