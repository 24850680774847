import { LazyProjectsPage } from "modules/investorRole/pages/ProjectsPage/lazy";
import { LazyProjectPage } from "modules/investorRole/pages/ProjectsPage/ProjectPage/lazy";
import ProjectAboutPage from "modules/investorRole/pages/ProjectsPage/ProjectPage/pages/ProjectAboutPage";
import ProjectDocsPage from "modules/investorRole/pages/ProjectsPage/ProjectPage/pages/ProjectDocsPage";
import ProjectOffersPage from "modules/investorRole/pages/ProjectsPage/ProjectPage/pages/ProjectOffersPage";
import { AnyObject } from "shared/types/common";
import { Route } from "shared/types/routes";

export const projectRoutes: AnyObject<Route> = {
  projects: {
    Element: LazyProjectsPage,
    path: "projects",
  },
  project: {
    Element: LazyProjectPage,
    path: "projects/:id/*",
    children: {
      about: {
        path: "about",
        Element: ProjectAboutPage,
      },
      docs: {
        path: "docs",
        Element: ProjectDocsPage,
      },
      changes: {
        path: "offers",
        Element: ProjectOffersPage,
      },
    },
  },
};
