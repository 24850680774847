import React from "react";
import { ImagesSection } from "./ImagesSection";
import { VideoSection } from "./VideoSection";

export const CardSettingsMediaPage = () => {
  return (
    <React.Fragment>
      <ImagesSection />
      <VideoSection />
    </React.Fragment>
  );
};
