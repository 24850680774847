import { useSocketConnect } from "shared/hooks/_sockets/useSocketConnect";

export const useAccountChangesConnect = () => {
  useSocketConnect({
    eventName: "account",
    notification: {
      id: "accountChanges",
      title: "Изменения по счету",
    },
  });
};
