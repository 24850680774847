import { Box, Center, Container, Image, Paper, Stack, Tabs, Title } from "@mantine/core";
import { ErrorFallback } from "components/_error/ErrorFallback";
import { Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Outlet } from "react-router-dom";
import logo from "shared/assets/images/logo.png";
import { useLinkTabs } from "shared/hooks/useLinkTabs";
import { Progress } from "shared/ui/_loaders/Progress";

const tabs = [
  {
    title: "Вход",
    path: "sign-in",
  },
  {
    title: "Регистрация",
    path: "sign-up",
  },
];

const AuthLayout = () => {
  const [activeTab, handleChangeAuth] = useLinkTabs(2);

  return (
    <Container mt={50} mb={50} size={600} h={"fit-content"}>
      <Center>
        <Image w={200} src={logo} />
      </Center>
      <Paper mt={10} withBorder radius={"lg"}>
        <Stack gap={"xl"}>
          <Box>
            <Tabs value={activeTab} onChange={handleChangeAuth}>
              <Tabs.List grow>
                {tabs.map(({ title, path }) => (
                  <Tabs.Tab key={path} value={path}>
                    <Title order={5}>{title}</Title>
                  </Tabs.Tab>
                ))}
              </Tabs.List>
            </Tabs>
          </Box>
          <Suspense fallback={<Progress />}>
            <Box>
              <ErrorBoundary FallbackComponent={ErrorFallback}>
                <Outlet />
              </ErrorBoundary>
            </Box>
          </Suspense>
        </Stack>
      </Paper>
    </Container>
  );
};

export default AuthLayout;
