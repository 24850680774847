import { Token } from "directual-api/lib/auth";
import { ROLES } from "shared/const/roles";
import { Fx } from "shared/types/api";
import sms from "./sms";
import { userFx } from "./user";
import { createApi } from "./_factories/createApi";

const structure = "auth";
const api = createApi({ structure });

export type SignInProps = {
  id: string;
  phone: string;
  password: string;
};

export type SignInData = string;

export type SignInPayload = {
  status: string;
  SMSSignature: string;
};
const signInNew: Fx<SignInData, SignInPayload, Error> = async (userId) =>
  await api().setItem<SignInPayload>("signIn", { userId });

export type SignUpData = {
  email: string;
  userId: string;
  phone: string;
} & (SignUpInvestorData | SignUpProjectData);

export type SignUpProjectData = {
  type: ROLES.project;
  inn: string;
};

export type SignUpInvestorData = {
  type: ROLES.investor;
  inn?: never;
};

const signUp: Fx<SignUpData, SignInPayload, Error> = async (data) =>
  await api().setItem<SignInPayload>("signUp", data);

const signIn: Fx<SignInProps, Token, Error> = async ({ phone, password, id }) => {
  const response = sms.check({ id, smsCodeInvestor: password });
  return response as any;
};

const checkAuth = userFx.info;

type Props = {
  sessionID: String;
};

const logout: Fx<Props, any, Error> = async ({ sessionID }) =>
  await api().auth.logout(sessionID as string);

const authFx = { signIn, checkAuth, logout, signInNew, signUp };

export default authFx;
