import { MultiSelect, Select } from "@mantine/core";
import { Controller } from "react-hook-form";
import { SelectInput as SelectInputType } from "shared/types/forms";
import { Sizes } from "shared/types/mantine";

import styles from "./index.module.scss";

type Props = {
  control: any;
  size: Sizes;
};

const rules = (required?: boolean) => ({
  required: required
    ? {
        value: true,
        message: "Поле телефон обязательно для заполнения",
      }
    : {},
});

export const SelectInput = (props: SelectInputType & Props) => {
  if (props.multiple)
    return (
      <Controller
        name={props.name}
        control={props.control}
        rules={rules(props.required) as any}
        render={({ field }) => {
          return (
            <MultiSelect
              data={props.data}
              size={props.size}
              label={props.label}
              placeholder={props.placeholder}
              className={styles.select}
              error={props.error as any}
              {...field}
            />
          );
        }}
      />
    );
  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={rules(props.required) as any}
      render={({ field }) => {
        return (
          <Select
            className={styles.select}
            data={props.data}
            size={props.size}
            label={props.label}
            placeholder={props.placeholder}
            error={props.error as any}
            {...field}
          />
        );
      }}
    />
  );
};
