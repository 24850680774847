import { Box, Overlay, ScrollArea, Table as TableMantine, Text } from "@mantine/core";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { useScrollTop } from "shared/hooks/useScrollTop";
import { Sizes } from "shared/types/mantine";
import { TableBody } from "shared/types/table";
import { CenterContainer } from "shared/ui/_containers/CenterContainer";
import { SkeletonTable } from "shared/ui/_skeletons/SkeletonTable";
import styles from "./index.module.scss";
import { TableContent } from "./TableContent";

type TableProps = {
  h: number | string;
  titles: string[];
  pending?: boolean;
  size?: Sizes;
  body: TableBody[] | null;
  scrollableRef?: React.RefObject<HTMLDivElement>;
  targetsRef?: React.RefObject<HTMLDivElement[]>;
};

export const Table = (props: TableProps) => {
  const { h, body, titles, pending, scrollableRef, targetsRef, size } = props;
  const [searchParams] = useSearchParams();
  const viewportScrollableRef = useScrollTop(searchParams);

  const tableTitles = titles.map((title, index) => (
    <TableMantine.Th key={index}>{title}</TableMantine.Th>
  ));

  const addToRefs = (el: any, index: number) => {
    if (el && targetsRef) {
      targetsRef!.current![index] = el;
    }
  };

  const emptyBody =
    !body && !pending ? (
      <Box h={70}>
        <Overlay backgroundOpacity={0}>
          <CenterContainer>
            <Text>Нет записей</Text>
          </CenterContainer>
        </Overlay>
      </Box>
    ) : null;

  const tableBody = body
    ? body?.map((item, index) => (
        <TableMantine.Tr ref={(el) => addToRefs(el, index)} key={index}>
          {Object.keys(item).map((key, i) => (
            <TableMantine.Td key={i}>
              <TableContent size={size} body={item[key]} />
            </TableMantine.Td>
          ))}
        </TableMantine.Tr>
      ))
    : null;

  const skeleton = pending ? <SkeletonTable rowsAmount={20} columnsAmount={titles.length} /> : null;

  return (
    <ScrollArea h={h} offsetScrollbars ref={scrollableRef} viewportRef={viewportScrollableRef}>
      <TableMantine stickyHeaderOffset={60} verticalSpacing="xs">
        <TableMantine.Thead className={styles.header} bg={"dark.7"}>
          <TableMantine.Tr>{tableTitles}</TableMantine.Tr>
        </TableMantine.Thead>
        <TableMantine.Tbody pos={"relative"} mih={200}>
          {emptyBody}
          {tableBody}
          {skeleton}
        </TableMantine.Tbody>
      </TableMantine>
    </ScrollArea>
  );
};
