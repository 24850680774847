import { Skeleton } from "@mantine/core";
import { ProjectTeamMember } from "components/project/ProjectTeamMember";
import { useTeamMembers } from "modules/projectRole/hooks/useTeamMembers";
import { TeamMember } from "shared/types/projects";

type Props = {
  members: TeamMember[] | null;
};
export const MainTeamMembers = ({ members }: Props) => {
  const {
    fetchGetByIds: { pending },
  } = useTeamMembers();
  const teamMembers = members?.map((member, index) => (
    <ProjectTeamMember key={member.id} {...member} index={index} />
  ));

  const loader = new Array(3).fill(0).map((_, index) => <Skeleton key={index} h={100} />);

  if (pending) return <>{loader}</>;

  if (members) return <>{teamMembers}</>;

  return null;
};
