import { notifications } from "@mantine/notifications";
import React from "react";
import { socket } from "shared/hooks/_sockets/useSocketsNotifications";

type Props = {
  eventName: string;
  notification: {
    id: string;
    title: string;
  };
  action?: () => void;
};
export const useSocketConnect = ({
  eventName,
  notification: notificationProps,
  action = () => {},
}: Props) => {
  React.useEffect(() => {
    socket.on(eventName, (message: string) => {
      notifications.show({
        ...notificationProps,
        message,
      });
      action();
    });
    return () => {
      socket.off(eventName);
    };
  }, []);
};
