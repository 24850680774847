import { Fx } from "shared/types/api";
import { AnyObject } from "shared/types/common";
import { createWithEqualityFn } from "zustand/traditional";
import { createSliceNew, SliceModern } from "./createSliceNew";

type Fxs<Payload> = AnyObject<Fx<any, Payload, Error>>;

export function createSimpleStore<Payload, Fetches>(fxs?: Fxs<Payload>) {
  const slice = createSliceNew<Payload, Fetches>({ fxs });
  return createWithEqualityFn<SliceModern<Payload, Fetches>>((set, get) => ({
    ...slice(set, get),
  }));
}
