import { getInitials } from "../../helpers/getInitials";
import { useCheckAuth } from "../_auth/useCheckAuth";

export const useUser = () => {
  const user = useCheckAuth().data!;
  const { lastName, firstName, middleName } = user;
  const fullName = [lastName, firstName, middleName].reduce(
    (fullName, part) => fullName + (part ? " " + part : ""),
    "",
  );
  const project = user?.projectLink;
  const initials = getInitials(firstName, lastName);

  return {
    fullName,
    initials,
    project,
    ...user,
  };
};
