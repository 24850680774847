import { Group, MantineSize, Progress, Text } from "@mantine/core";
import React from "react";
import { ProgressBody } from "shared/types/table";

export type TableProgressProps = {
  body: ProgressBody;
  size: MantineSize | string | undefined;
};

const formatPercentage = (value: number) => {
  return Intl.NumberFormat("ru-RU", {
    style: "percent",
    maximumFractionDigits: 0,
  }).format(value);
};

export const TableProgress = ({ size, body }: TableProgressProps) => {
  const { positive, negative } = body.value;

  const fill = positive / negative;
  const remain = Math.max((negative - positive) / negative, 0);
  const progress = isFinite(fill) ? Math.min(fill * 100, 100) : 100;

  return (
    <React.Fragment>
      <Group wrap={"nowrap"} justify="space-between">
        <Text fz="xs" size={size} c="gold">
          {isFinite(fill) ? formatPercentage(fill) : "100%"}
        </Text>
        <Text fz="xs" size={size} c="dark.2">
          {isFinite(remain) ? formatPercentage(remain) : "0%"}
        </Text>
      </Group>
      <Progress value={progress} color="gold" />
    </React.Fragment>
  );
};
