import { notifications } from "@mantine/notifications";
import React from "react";
import { Navigate } from "react-router-dom";

const NOTIFICATION = {
  id: "smsIdDoesNotExist",
  message: "СМС ID не найден",
  color: "red",
};

export const CheckSMSError = () => {
  React.useEffect(() => {
    return () => {
      notifications.show(NOTIFICATION);
    };
  }, []);

  return <Navigate to={"/"} />;
};
