import React from "react";

type UseTimer = (startTime: number) => [number, () => void];

export const useTimer: UseTimer = (startTime) => {
  const [restOfTime, setRestOfTime] = React.useState<number>(startTime);

  React.useEffect(() => {
    if (restOfTime > 0) {
      setTimeout(() => {
        setRestOfTime(restOfTime - 1);
      }, 1000);
    }
  }, [restOfTime]);

  const handleRestart = () => setRestOfTime(startTime);

  return [restOfTime, handleRestart];
};
