import { Fx } from "../types/api";
import { createApi } from "./_factories/createApi";

const structure = "uploads";
const api = createApi({ structure });

export type FileParams = {
  sessionID: String;
  file: File;
};
export const uploadFileFX: Fx<FileParams, string, Error> = async (params) => {
  const { sessionID, file } = params;
  const formData = new FormData();
  formData.append("file", file);
  const response = await api().setItem<any>("uploadFile", formData, { sessionID });
  return response.file.urlLink;
};
