import { PrivateRoute } from "components/_private/PrivateRoute";
import { LazyAccountPage } from "modules/projectRole/pages/AccountPage/lazy";
import { ROUTES } from "shared/const/ROUTES";
import { AnyObject } from "shared/types/common";
import { Route } from "shared/types/routes";

export const accountRoutes: AnyObject<Route> = {
  privateProjectAccount: {
    Element: () => <PrivateRoute type={"projectAccount"} />,
    children: {
      accounts: {
        path: ROUTES.PROJECT.ACCOUNT.ROOT,
        Element: LazyAccountPage,
      },
    },
  },
};
