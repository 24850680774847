import { accountEntrepreneurForm, accountIndividualForm } from "./account";
import { passportForm } from "./passport";
import { quizForm } from "./quiz";

export const FORMS = {
  passportForm,
  accountIndividualForm,
  accountEntrepreneurForm,
  quizForm,
};
