import { Control, FieldValues } from "react-hook-form";
import { AnyInput } from "shared/types/forms";
import { Sizes } from "shared/types/mantine";
import { formInputConfig } from "./config";

type NotFormProps = {
  control: Control<FieldValues, any>;
  size?: Sizes;
};

type Props = NotFormProps & AnyInput;

const { getInput } = formInputConfig;

export const FormInput = (props: Props) => {
  const { type } = props;
  return getInput({ type, props });
};
