import { SimpleGrid } from "@mantine/core";
import { get } from "lodash";
import React from "react";
import { ModernForm } from "shared/types/forms";
import { Sizes } from "shared/types/mantine";
import { FormInput } from "../FormInput";

type Props = {
  form: ModernForm;
  control: any;
  errors: any;
  setValue?: (name: string, value: unknown, config?: Object) => void;
  size?: Sizes;
};

export const FormInputs = ({ form, errors, ...inputProps }: Props) => {
  return (
    <React.Fragment>
      {form.map((item, i) => {
        if (Array.isArray(item)) {
          return (
            <SimpleGrid key={i} cols={{ base: 1, xs: 2 }} spacing={{ base: "xl", xs: "md" }}>
              {item.map(({ name, ...props }: any, i: number) => (
                <FormInput
                  key={name}
                  {...props}
                  name={name}
                  error={get(errors, name)?.message}
                  {...inputProps}
                />
              ))}
            </SimpleGrid>
          );
        }
        const { name, ...props } = item;
        return (
          <FormInput
            key={i}
            {...props}
            name={name as any}
            {...inputProps}
            error={get(errors, name)?.message}
          />
        );
      })}
    </React.Fragment>
  );
};
