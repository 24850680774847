import { useTeamMembersStore } from "modules/projectRole/stores/useTeamMembers";
import { useSendError } from "shared/hooks/useSendError";
import { useSessionID } from "shared/hooks/_auth/useSessionID";
import { TeamMember } from "shared/types/projects";

export const useTeamMembers = () => {
  const { data, fetchGetByProject, fetchGetByIds, fetchSetItems } = useTeamMembersStore();
  const sessionID = useSessionID()!;

  const handleGetByProject = (project: string) => {
    fetchGetByProject.fx({ sessionID, project });
  };

  const handleGetByIds = (ids: string[]) => {
    fetchGetByIds.fx({ sessionID, ids: ids.join(",") });
  };

  const handleSetTeamMembers = (body: TeamMember[]) => {
    fetchSetItems.fx({ params: { sessionID }, body });
  };

  useSendError(fetchSetItems.error || fetchGetByIds.error || fetchGetByProject.error);

  return {
    data,
    fetchGetByProject,
    handleGetByProject,
    handleGetByIds,
    handleSetTeamMembers,
    fetchSetItems,
    fetchGetByIds,
  };
};
