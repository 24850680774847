import { Paper, Spoiler, Stack, Title } from "@mantine/core";
import { ProjectTeamMember } from "components/project/ProjectTeamMember";
import { useProjects } from "stores/useProjects";

const ProjectAboutTeam = () => {
  const project = useProjects(({ project: { data } }) => data);

  if (!project?.teamMembers) return null;

  const teamMembers = project?.teamMembers?.map((member, index) => (
    <ProjectTeamMember key={member.id} {...member} index={index} />
  ));

  return (
    <Paper component={Stack} gap={"sm"}>
      <Title order={4}>Команда</Title>
      <Spoiler maxHeight={100} hideLabel={"Скрыть"} showLabel={"показать еще"}>
        <Stack gap={"sm"}>{teamMembers}</Stack>
      </Spoiler>
    </Paper>
  );
};

export default ProjectAboutTeam;
