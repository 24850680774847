import { FileRejection } from "@mantine/dropzone";

export const getFileUploadErrors = (
  rejections: FileRejection[],
  {
    maxSize,
    accept,
  }: {
    maxSize?: number;
    accept?: string;
  },
): { title: string; message: string }[] => {
  const getError = (error: FileRejection["errors"][number]) => {
    if (error.code === "file-too-large" && typeof maxSize === "number") {
      const convertBytesToMB = () => maxSize / 1024 ** 2;

      const maxSizeFormatted = `${convertBytesToMB()}MB`;
      return {
        title: "Слишком большой файл",
        message: `Размер файла не должен превышать ${maxSizeFormatted}`,
      };
    }

    if (error.code === "file-invalid-type" && accept) {
      return {
        title: "Недопустимый тип файла",
        message: `Разрешённые типы файла: ${accept}`,
      };
    }

    return {
      title: "Ошибка загрузки файла",
      message: "При загрузке файла произошла ошибка",
    };
  };

  return rejections.reduce(
    (acc, { errors }) => {
      if (errors.length > 0) {
        acc.push(...errors.map(getError));
      }

      return acc;
    },
    [] as ReturnType<typeof getFileUploadErrors>,
  );
};
