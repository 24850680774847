import { createApi } from "shared/api/_factories/createApi";
import { Fx, SIDParams } from "shared/types/api";
import { CardSettings } from "shared/types/projects";

const structure = "cardsettings";
const api = createApi({ structure });

export type CardSettingsGetByOwner = SIDParams;

const getItemByOwner: Fx<CardSettingsGetByOwner, CardSettings, Error> = (params) =>
  api().getItem("getByOwner", params);

export type CardSettingsEditItem = {
  params: SIDParams;
  data: CardSettings;
};
const editItemByOwner: Fx<CardSettingsEditItem, CardSettings, Error> = ({ params, data }) =>
  api().setItem("edit", data, params);

export type CardSettingsEditItemDefault = any;

const getDefaultDataForForm: Fx<CardSettingsGetByOwner, CardSettings, Error> = async (params) => {
  return await api().getItem("getDefaultDataForForm", params);
};

type Id = {
  id: string;
};

export type CardSettingsCheckItemProps = {
  params: SIDParams;
  body: Id;
};

const checkItem: Fx<CardSettingsCheckItemProps, CardSettings, Error> = async ({ body, params }) => {
  return await api().setItem("check", body, params);
};

export const cardSettingsFx = {
  getItemByOwner,
  editItemByOwner,
  getDefaultDataForForm,
  checkItem,
};
