import {
  defaultVariantColorsResolver,
  parseThemeColor,
  VariantColorsResolver,
} from "@mantine/core";

const colors = ["gold", "green", "red"];

const color = "var(--mantine-color-dark-9)";

export const variantColorResolver: VariantColorsResolver = (input) => {
  const defaultResolvedColors = defaultVariantColorsResolver(input);
  const parsedColor = parseThemeColor({
    color: input.color || input.theme.primaryColor,
    theme: input.theme,
  });

  if (
    input.variant === "filled" &&
    colors.includes(parsedColor.color) &&
    parsedColor.isThemeColor
  ) {
    return { ...defaultResolvedColors, color };
  }

  return defaultResolvedColors;
};
