import { LazyProjectInfoPage } from "modules/projectRole/pages/ProjectInfoPage/lazy";
import { ROUTES } from "shared/const/ROUTES";
import { AnyObject } from "shared/types/common";
import { Route } from "shared/types/routes";

export const projectInfoRoute: AnyObject<Route> = {
  projectInfo: {
    path: ROUTES.PROJECT.PROJECT_INFO,
    Element: LazyProjectInfoPage,
  },
};
