import React from "react";
import { Rule } from "../ui/BadgeStatus";
import { AnyObject } from "./common";

export enum BodyTypes {
  text = "text",
  number = "number",
  price = "price",
  boolean = "boolean",
  button = "button",
  actionIcon = "actionIcon",
  group = "group",
  picture = "picture",
  date = "date",
  status = "status",
  progress = "progress",
  balanceChange = "balanceChange",
}

export type TableBody = Record<string, AllTypes>;

export type TextBody = {
  value: string;
  type: BodyTypes.text;
  variants?: never;
  onClick?: never;
  disabled?: never;
};

export type NumberBody = {
  value: number;
  type: BodyTypes.number;
  variants?: never;
  onClick?: never;
  disabled?: never;
};

export type CurrencyBody = {
  value: number;
  type: BodyTypes.price;
  variants?: never;
  onClick?: never;
  disabled?: never;
};

export type BooleanBody = {
  value: boolean;
  type: BodyTypes.boolean;
  variants: string[];
  onClick?: never;
  disabled?: never;
};

export type ButtonBody = {
  value: string;
  type: BodyTypes.button;
  onClick: () => void;
  disabled?: boolean;
  variants?: never;
};

export type ActionIconBody = {
  value: React.ReactNode | React.JSX.Element;
  type: BodyTypes.actionIcon;
  onClick: () => void;
  disabled?: boolean;
  variants?: never;
};

export type GroupBody = {
  value: string[];
  type: BodyTypes.group;
  onClick?: never;
  variants?: never;
  disabled?: never;
};

export type PictureBody = {
  value: string;
  type: BodyTypes.picture;
  onClick?: never;
  variants?: never;
  disabled?: never;
};

export type DateBody = {
  value: string | number;
  type: BodyTypes.date;
  variants?: never;
  onClick?: never;
  disabled?: never;
};
export type StatusBody = {
  value: string;
  variants: AnyObject<Rule>;
  type: BodyTypes.status;
  onClick?: never;
  disabled?: never;
};

export type TableBalanceChange = {
  value: number;
  isReceipt: boolean;
  type: BodyTypes.balanceChange;
  onClick?: never;
  disabled?: never;
};

export type ProgressBody = {
  value: {
    positive: number;
    negative: number;
  };
  type: BodyTypes.progress;
  variants?: never;
  onClick?: never;
  disabled?: never;
};

export type AllTypes =
  | TextBody
  | NumberBody
  | ButtonBody
  | BooleanBody
  | GroupBody
  | CurrencyBody
  | PictureBody
  | DateBody
  | StatusBody
  | ProgressBody
  | ActionIconBody
  | TableBalanceChange;

export type TableRow<Data> = {
  data: Data;
  onClick?: (props?: any) => void;
};

export type CreateRow<Data> = (props: TableRow<Data>, ...attrs: any) => TableBody;

export type TableConfig<Data> = {
  titles: string[];
  createBody: CreateRow<Data>;
};
