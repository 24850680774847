import { notifications } from "@mantine/notifications";

type Payload = () => void;

type Props = {
  title?: string;
  message?: string;
};

export const useWorkInProgress = (props?: Props): Payload => {
  const handleWorkInProgress = () => {
    notifications.show({
      title: (props && props.title) || "Функционал находится в разработке",
      message: (props && props.message) || "В ближайшее время он появится😌",
    });
  };

  return handleWorkInProgress as any;
};
