import { Box, Button, Stack, Text, Title } from "@mantine/core";
import { CheckSMSResendButton } from "pages/_service/CheckSMSPage/components/CheckSMSRouter/variants/CheckSMSCheck/CheckSMSForm/CheckSMSResendButton";
import {
  CHECK_SMS_FORM_KEYS,
  SMS_CODE_LENGTH,
} from "pages/_service/CheckSMSPage/components/CheckSMSRouter/variants/CheckSMSCheck/CheckSMSForm/const";
import { useCheckSMSForm } from "pages/_service/CheckSMSPage/components/CheckSMSRouter/variants/CheckSMSCheck/CheckSMSForm/hook";
import { PinInput } from "react-hook-form-mantine";
import { phoneMask } from "shared/helpers/phoneMask";

export const CheckSMSForm = () => {
  const { checkSMS, resendSMS, phone } = useCheckSMSForm();

  return (
    <form onSubmit={checkSMS.handleCheck}>
      <Stack gap={"xl"}>
        <Box>
          <Title mb={10} order={1}>
            Проверка СМС
          </Title>
          <Text>Введите СМС код который мы вам направили на номер:</Text>
          <Text fw={700}>{phoneMask(phone)}</Text>
        </Box>
        <Stack>
          <PinInput
            control={checkSMS.control}
            name={CHECK_SMS_FORM_KEYS.smsCodeInvestor}
            length={SMS_CODE_LENGTH}
            type={"number"}
            size={"xl"}
          />
        </Stack>
        <Stack gap={"xs"}>
          <Button
            type={"submit"}
            loading={checkSMS.pending}
            disabled={resendSMS.pending}
            size={"lg"}
          >
            Отправить код
          </Button>
          <CheckSMSResendButton
            disabled={checkSMS.pending}
            pending={resendSMS.pending}
            handleResend={resendSMS.handleResend}
          />
        </Stack>
      </Stack>
    </form>
  );
};
