import { PrivateRoute } from "components/_private/PrivateRoute";
import { LazyCardSettingsPage } from "modules/projectRole/pages/CardSettingsPage/lazy";
import { CardSettingsCommonPage } from "modules/projectRole/pages/CardSettingsPage/pages/CardSettingsCommonPage";
import { CardSettingsMediaPage } from "modules/projectRole/pages/CardSettingsPage/pages/CardSettingsMediaPage";
import { CardSettingsTeamPage } from "modules/projectRole/pages/CardSettingsPage/pages/CardSettingsTeamPage";
import { ROUTES } from "shared/const/ROUTES";
import { AnyObject } from "shared/types/common";
import { Route } from "shared/types/routes";

export const projectCardRoutes: AnyObject<Route> = {
  privateProjectCard: {
    Element: () => <PrivateRoute type={"cardSettings"} />,
    children: {
      projectCard: {
        Element: LazyCardSettingsPage,
        path: ROUTES.PROJECT.CARD.SETTINGS.PATH,
        children: {
          common: {
            Element: CardSettingsCommonPage,
            path: "common",
          },
          media: {
            Element: CardSettingsMediaPage,
            path: "media",
          },
          team: {
            Element: CardSettingsTeamPage,
            path: "team",
          },
        },
      },
    },
  },
};
