import { MantineSize } from "@mantine/core";
import { Sizes } from "shared/types/mantine";
import { BooleanBody } from "shared/types/table";
import { BadgeStatus } from "shared/ui/BadgeStatus";

export type TableBooleanProps = {
  body: BooleanBody;
  size: MantineSize | string | undefined;
};
export const TableBoolean = ({ size, body }: TableBooleanProps) => {
  return (
    <BadgeStatus
      status={body.value}
      warning={body.variants[0]}
      success={body.variants[1]}
      size={size as Sizes}
    />
  );
};
