import { TableActionIcon, TableActionIconProps } from "./components/TableActionIcon";
import { TableBalanceChange, TableBalanceChangeProps } from "./components/TableBalanceChange";
import { TableBoolean, TableBooleanProps } from "./components/TableBoolean";
import { TableButton, TableButtonProps } from "./components/TableButton";
import { TableCurrency, TableCurrencyProps } from "./components/TableCurrency";
import { TableDate, TableDateProps } from "./components/TableDate";
import { TableGroup, TableGroupProps } from "./components/TableGroup";
import { TableNumber, TableNumberProps } from "./components/TableNumber";
import { TablePicture, TablePictureProps } from "./components/TablePicture";
import { TableProgress, TableProgressProps } from "./components/TableProgress";
import { TableStatus, TableStatusProps } from "./components/TableStatus";
import { TableText, TableTextProps } from "./components/TableText";

export const TableContentConfig = {
  content: {
    text: (props: TableTextProps) => <TableText {...props} />,
    number: (props: TableNumberProps) => <TableNumber {...props} />,
    price: (props: TableCurrencyProps) => <TableCurrency {...props} />,
    button: (props: TableButtonProps) => <TableButton {...props} />,
    boolean: (props: TableBooleanProps) => <TableBoolean {...props} />,
    status: (props: TableStatusProps) => <TableStatus {...props} />,
    group: (props: TableGroupProps) => <TableGroup {...props} />,
    picture: (props: TablePictureProps) => <TablePicture {...props} />,
    date: (props: TableDateProps) => <TableDate {...props} />,
    progress: (props: TableProgressProps) => <TableProgress {...props} />,
    actionIcon: (props: TableActionIconProps) => <TableActionIcon {...props} />,
    balanceChange: (props: TableBalanceChangeProps) => <TableBalanceChange {...props} />,
  },
};
