import { ModernForm } from "../../types/forms";

export const passportForm: ModernForm = [
  {
    name: "lastName",
    type: "text",
    label: "Фамилия",
    placeholder: "Иванов",
    required: true,
  },
  [
    {
      name: "firstName",
      type: "text",
      label: "Имя",
      placeholder: "Иван",
      required: true,
    },
    {
      name: "middleName",
      type: "text",
      label: "Отчество",
      placeholder: "Иванович",
      required: true,
    },
  ],
  [
    {
      name: "serial",
      type: "numeric",
      label: "Серия",
      length: 4,
      placeholder: "1912",
      required: true,
    },
    {
      name: "number",
      type: "numeric",
      label: "Номер",
      length: 6,
      placeholder: "543678",
      required: true,
    },
  ],
  {
    name: "issuedBy",
    type: "text",
    label: "Кем выдан",
    placeholder: "ОУФМС России в гор. Санкт-Петербург",
    required: true,
  },
  [
    {
      name: "divisionCode",
      type: "custom",
      mask: "###-###",
      label: "Код подразделения",
      placeholder: "640-200",
      required: true,
    },
    {
      name: "dateOfIssue",
      type: "date",
      label: "Дата выдачи",
      maxDate: new Date(),
      minDate: new Date(1900, 0, 1),
      placeholder: "12.10.2002",
      required: true,
    },
  ],
  [
    {
      name: "birthday",
      type: "date",
      label: "Дата рождения",
      maxDate: new Date(),
      minDate: new Date(1900, 0, 1),
      placeholder: "12.10.1990",
      required: true,
    },
    {
      name: "birthPlace",
      type: "text",
      label: "Место рождения",
      placeholder: "гор. Санкт-Петербург",
      required: true,
    },
  ],
  {
    name: "registrationAddress",
    type: "text",
    label: "Адрес регистрации",
    placeholder: "гор. Санкт-Петербург, пр-кт Оптиков, 34к1, кв. 357",
    required: true,
  },
];
