import { CONST } from "../const";
import { toArray } from "./toArray";

const { ruDate } = CONST.REGEX;

export const toStandardDate = (date: string): string | undefined => {
  if (ruDate.test(date)) {
    return toArray(date, ".").reverse().join("-");
  } else return date;
};
