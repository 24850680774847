import authFx from "shared/api/auth";
import { SIDParams } from "shared/types/api";
import { FxSlice } from "shared/types/store";
import { User } from "shared/types/user";
import { createSimpleStore } from "stores/_helpers/factories/createSimpleStore";

type Fxs = { fetchCheckAuth: FxSlice<SIDParams> };

const { checkAuth } = authFx;
export const useCheckAuth = createSimpleStore<User, Fxs>({
  fetchCheckAuth: checkAuth,
});
