import { dealsFx, GetAllDealsParams } from "shared/api/deals";
import { Deal } from "shared/types/deals";
import { FxSlice } from "shared/types/store";
import { createSliceNew } from "stores/_helpers/factories/createSliceNew";

export type CreateDealsFxs = {
  fetchGet: FxSlice<GetAllDealsParams>;
};

export const dealsSlice = createSliceNew<Deal[], CreateDealsFxs>({
  key: "deals",
  fxs: { fetchGet: dealsFx.getAll },
});
