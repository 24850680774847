import dayjs from "dayjs";
import { CONST } from "../const";

const { standardDate } = CONST.REGEX;

export const toFormatDate = (
  date: string | number,
  format: string = "DD.MM.YYYY",
): string | undefined => {
  return dayjs(date).locale("ru").format(format);
};
