import { Button, Flex, Stack, Text, Title } from "@mantine/core";

export const ErrorFallback = () => {
  return (
    <Flex w={"100%"} justify="center">
      <Stack h={"100%"} justify="center" align="center" py={40}>
        <Title order={3}>Упс... Что-то пошло не так</Title>
        <Text size="md">Попробуйте обновить страницу</Text>
        <Button onClick={() => window.location.reload()}>Обновить</Button>
      </Stack>
    </Flex>
  );
};
