import { Navigate, Outlet } from "react-router-dom";
import { useCheckAuth } from "../../../shared/hooks/_auth/useCheckAuth";
import { CenterContainer } from "../../../shared/ui/_containers/CenterContainer";
import { MainLoader } from "../../../shared/ui/_loaders/MainLoader";

export const CheckNoAuth = () => {
  const checkAuth = useCheckAuth();
  const loading = checkAuth.pending || !checkAuth.data;
  const notAuth = !checkAuth.data && checkAuth.error;

  if (notAuth) return <Outlet />;

  if (loading) {
    return (
      <CenterContainer>
        <MainLoader />
      </CenterContainer>
    );
  }

  return <Navigate to={"/"} />;
};
