import { REGEX } from "shared/const/regex";
import { z } from "zod";

export const segmentsConfig = [
  { label: "Общие сведения", value: "common" },
  { label: "Медиа", value: "media" },
  { label: "Команда", value: "team" },
];
export const CARD_SETTINGS_NAMES = {
  name: "name",
  description: "description",
  aboutProject: "aboutProject",
  images: "images",
  logo: "logo",
  teamMembers: "teamMembers",
  video: "video",
  categories: "categories",
};

export const cardSettingsSchema = z.object({
  video: z
    .string()
    .refine(
      (value: string) => REGEX.youtube.test(value ?? ""),
      "Ссылка должна вести на YouTube и содержать www",
    )
    .optional()
    .or(z.literal("")),
  description: z
    .string({ required_error: "Добавьте описание проекта" })
    .min(20, "Описание должно быть минимум из 20 символов"),
  logo: z.string().min(1, "Добавьте логотип"),
  aboutProject: z
    .string({ required_error: "Добавьте информацию о проекте" })
    .min(50, "О проекте должно состоять минимум из 50 символов"),
  categories: z.array(z.string()).min(1, "Добавьте хотя бы 1 категорию"),
  images: z.array(z.string()).optional(),
  teamMembers: z.array(z.string()).optional(),
});
