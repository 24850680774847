import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { linkParser } from "shared/helpers/linkParser";

type UseLinkTabs = (order: number) => [string, (path: string | null) => void];

export const useLinkTabs: UseLinkTabs = (order: number) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = React.useState("");

  React.useEffect(() => {
    setActiveTab(linkParser(location.pathname, order) as string);
  }, []);

  const handleChangeAuth = (path: string | null) => {
    navigate(path as string);
    setActiveTab(path as string);
  };

  return [activeTab, handleChangeAuth];
};
