import { userFx } from "shared/api/user";
import { SIDParams } from "shared/types/api";
import { User } from "shared/types/user";
import { createSliceModern, SliceModern } from "stores/_helpers/factories/createSliceModern";
import { shallow } from "zustand/shallow";
import { createWithEqualityFn } from "zustand/traditional";

export const userSlice = createSliceModern<User, SIDParams, void, void>({
  key: "user",
  getFx: userFx.info,
});

type UseUser = {
  user: SliceModern<User, SIDParams, void, void>;
};

export const useUser = createWithEqualityFn<UseUser>(
  (set, get) => ({
    user: userSlice(set, get),
  }),
  shallow,
);
