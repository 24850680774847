export const isPersonalInn = (inn: string) => {
  const multiplieds = [
    [7, 2, 4, 10, 3, 5, 9, 4, 6, 8, 0],
    [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8, 0],
  ];
  const checkNumbers = [inn[inn.length - 2], inn[inn.length - 1]];

  const getRaised = (multiplied: number[]) => {
    const innNumbers = inn.split("").map((num) => Number(num));
    const sumOfProducts = multiplied.reduce((acc, inn, index) => acc + inn * innNumbers[index], 0);
    return sumOfProducts % 11 > 9 ? (sumOfProducts % 11) % 10 : sumOfProducts % 11;
  };

  const check = checkNumbers.reduce(
    (acc, item, index) => getRaised(multiplieds[index]) === Number(item) && acc,
    true,
  );

  return check || "Некорректный ИНН";
};
