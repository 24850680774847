import { Divider, Stack } from "@mantine/core";
import { DateInput as DateInputComp } from "@mantine/dates";
import { Controller, FieldError, FieldErrorsImpl, Merge } from "react-hook-form";
import { toStandardDate } from "shared/helpers/toStandardDate";
import { Sizes } from "shared/types/mantine";

type Props = {
  name: string;
  control: any;
  size?: Sizes;
  label?: string;
  required?: boolean;
  error?: string | FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined;
  minDate?: Date;
  maxDate?: Date;
  placeholder?: string;
  data: string;
  disabled?: boolean;
};

const rules = (required?: boolean) => ({
  required: required
    ? {
        value: true,
        message: "Поле обязательно для заполнения",
      }
    : {},
  pattern: {},
});

export const SettingsDateInput = ({
  name,
  control,
  size,
  required,
  label,
  error,
  maxDate,
  minDate,
  placeholder,
  data,
  disabled,
}: Props) => (
  <Stack gap={"xs"}>
    <Controller
      name={name}
      control={control}
      defaultValue={data}
      rules={rules(required) as any}
      render={({ field: { onChange, onBlur, value } }) => {
        let currentValue = toStandardDate(value)!;
        return (
          <DateInputComp
            size={size}
            disabled={disabled}
            value={currentValue ? new Date(currentValue) : null}
            label={label}
            valueFormat={"DD.MM.YYYY"}
            required={required}
            minDate={minDate}
            maxDate={maxDate}
            placeholder={placeholder}
            error={error as any}
            onChange={onChange}
            onBlur={onBlur}
          />
        );
      }}
    />
    <Divider />
  </Stack>
);
