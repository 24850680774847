import { Badge } from "@mantine/core";
import { Sizes } from "shared/types/mantine";
import { AnyObject } from "../../types/common";

type WithoutRules = {
  status: boolean;
  success: string;
  warning: string;
  colorWarning?: string;
  size?: Sizes;
  rules?: never;
};

export type Rule = {
  color: string;
  value: string;
  label: string;
};

type WithRules = {
  status: string;
  rules: AnyObject<Rule>;
  colorWarning?: never;
  size?: Sizes;
  success?: never;
  warning?: never;
};

export type BadgeProps = WithRules | WithoutRules;

export const BadgeStatus = ({
  status,
  success,
  warning,
  size,
  rules,
  colorWarning,
}: BadgeProps) => {
  const statusObj = status
    ? {
        color: "green",
        text: success,
      }
    : {
        color: colorWarning || "gold",
        text: warning,
      };
  const text = rules ? rules[status].label : statusObj.text;
  const color = rules ? rules[status].color : statusObj.color;

  return (
    <Badge size={size} color={color}>
      {text}
    </Badge>
  );
};
