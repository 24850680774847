import React from "react";
import { Checkbox as CheckboxMantine, PinInput } from "react-hook-form-mantine";
import { AnyObject } from "shared/types/common";
import { AnyInput } from "shared/types/forms";
import { DatePickerRange } from "shared/ui/_forms/_common/_inputs/DatePickerRange";
import { Checkbox } from "../_inputs/Checkbox";
import { CustomInput } from "../_inputs/CustomInput";
import { DateInput } from "../_inputs/DateInput";
import { FileInput } from "../_inputs/FileInput";
import { ImageInput } from "../_inputs/ImageInput";
import { NumberInput } from "../_inputs/NumberInput";
import { NumericInput } from "../_inputs/NumericInput";
import { OrgPhoneInput } from "../_inputs/OrgPhoneInput";
import { PhoneInput } from "../_inputs/PhoneInput";
import { Radio } from "../_inputs/Radio";
import { SegmentedControl } from "../_inputs/SegmentedControl";
import { SelectInput } from "../_inputs/SelectInput";
import { TextInput as TextInputComp } from "../_inputs/TextInput";
import { TextareaInput } from "../_inputs/TextareaInput";
import { SettingsDateInput } from "../_inputs/_settings/DateInput";
import { TextInput as SettingsTextInput } from "../_inputs/_settings/TextInput";

type GetInputProps = {
  type: string;
  props: AnyInput;
};

type FormInputConfig = {
  variants: AnyObject<React.FC<any>>;
  getInput: (props: GetInputProps) => React.JSX.Element;
};
export const formInputConfig: FormInputConfig = {
  variants: {
    text: TextInputComp,
    phone: PhoneInput,
    orgPhone: OrgPhoneInput,
    pin: PinInput,
    textarea: TextareaInput,
    date: DateInput,
    numeric: NumericInput,
    custom: CustomInput,
    checkboxItem: CheckboxMantine,
    select: SelectInput,
    checkbox: Checkbox,
    radio: Radio,
    segmentedControl: SegmentedControl,
    settingsText: SettingsTextInput,
    settingsDate: SettingsDateInput,
    file: FileInput,
    image: ImageInput,
    number: NumberInput,
    datePickerRange: DatePickerRange,
  },
  getInput: ({ type, props }) => <>{formInputConfig.variants[type](props)}</>,
};
