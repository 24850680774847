import { LazyProfilePage } from "pages/_private/ProfilePage/lazy";
import { AnyObject } from "shared/types/common";
import { Route } from "shared/types/routes";

export const profileRoutes: AnyObject<Route> = {
  profile: {
    Element: LazyProfilePage,
    path: "profile/*",
  },
};
