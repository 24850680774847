import { Fx, SIDParams } from "shared/types/api";
import { User } from "../types/user";
import { createApi } from "./_factories/createApi";

const structure = "WebUser";
const api = createApi({ structure });

type GetProps = SIDParams;

export type SetAvatarProps = {
  params: SIDParams;
  data: {
    id: string;
    userpic: string;
  };
};

export type DeleteAvatarProps = {
  params: SIDParams;
  data: {
    id: string;
  };
};

export type SetAvatarResponseData = {
  id: string;
  userpic: string;
};

export type DeleteAvatarResponseData = {
  id: string;
  userpic: string;
};

const info: Fx<GetProps, User, Error> = async (params) =>
  await api().getItem<User>("getInfo", params);

const setAvatar: Fx<SetAvatarProps, SetAvatarResponseData, Error> = async ({ data, params }) =>
  await api().setItem<SetAvatarResponseData>("setUserPic", data, params);

const deleteAvatar: Fx<DeleteAvatarProps, DeleteAvatarResponseData, Error> = async ({
  data,
  params,
}) => await api().setItem<DeleteAvatarResponseData>("deleteUserPic", data, params);

export const userFx = { info, setAvatar, deleteAvatar };
