import { Center, Popover, rem, Text } from "@mantine/core";
import { IconInfoCircle } from "@tabler/icons-react";
import React from "react";
import { TooltipInfoIconProps } from "shared/ui/_forms/_common/_inputs/_components/TooltipInfoIcon/types";

export const PopoverInfoIcon = ({ visible, label }: TooltipInfoIconProps) => {
  const [opened, setOpened] = React.useState(false);

  if (!visible) return null;

  return (
    <Popover width={200} opened={opened} onChange={setOpened} position="top" withArrow shadow="md">
      <Popover.Target>
        <Text
          component="div"
          onClick={() => setOpened(!opened)}
          onMouseOver={() => setOpened(true)}
          c="dimmed"
          style={{ cursor: "pointer" }}
        >
          <Center>
            <IconInfoCircle style={{ width: rem(18), height: rem(18) }} stroke={1.5} />
          </Center>
        </Text>
      </Popover.Target>
      <Popover.Dropdown>
        <Text size="xs">{label}</Text>
      </Popover.Dropdown>
    </Popover>
  );
};
