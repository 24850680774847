export const getIframeYoutubeLink = (standardYoutubeLink: string) => {
  const YOUTUBE_LINK = "https://www.youtube.com/watch?v=";
  const YOUTUBE_SHORTS_LINK = "https://www.youtube.com/shorts/";
  const YOUTUBE_EMBED = "https://www.youtube.com/embed/";

  if (standardYoutubeLink.includes(YOUTUBE_LINK)) {
    return YOUTUBE_EMBED + standardYoutubeLink.replace(YOUTUBE_LINK, "");
  } else if (standardYoutubeLink.includes(YOUTUBE_SHORTS_LINK)) {
    const id = standardYoutubeLink.replace(YOUTUBE_SHORTS_LINK, "");
    return `${YOUTUBE_EMBED}${id}?autoplay=1`;
  } else {
    console.error("Wrong link format");
    return "";
  }
};
