import { Grid } from "@mantine/core";
import { ImagePreview } from "shared/ui/_forms/_common/_inputs/FileInput/components/ImageList/ImagePreview";

import styles from "./index.module.scss";

type Props = {
  fileLinks: string[];
  handleRemoveFile: (index: number) => void;
};
export const ImageList = ({ fileLinks, handleRemoveFile }: Props) => {
  const images = fileLinks.map((photoLink, index) => (
    <Grid.Col span={4} key={index}>
      <ImagePreview onRemove={handleRemoveFile} imageUrl={photoLink} index={index} />
    </Grid.Col>
  ));

  return (
    <div className={styles.imageListContainer}>
      <Grid>{images}</Grid>
    </div>
  );
};
