import { Textarea } from "@mantine/core";
import { Controller, FieldError, FieldErrorsImpl, Merge } from "react-hook-form";
import { Sizes } from "shared/types/mantine";

import styles from "./index.module.scss";

type Props = {
  name: string;
  control: any;
  size?: Sizes;
  label?: string;
  required?: boolean;
  placeholder?: string;
  error?: string | FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined;
};

const rules = (required?: boolean) => ({
  required: required
    ? {
        value: true,
        message: "Поле обязательно для заполнения",
      }
    : {},
  pattern: {},
});

export const TextareaInput = ({
  name,
  control,
  size,
  required,
  label,
  error,
  placeholder,
}: Props) => (
  <Controller
    name={name}
    defaultValue={""}
    control={control}
    rules={rules(required) as any}
    render={({ field: { onChange, onBlur, value } }) => {
      return (
        <Textarea
          size={size}
          value={value}
          label={label}
          className={styles.input}
          required={required}
          placeholder={placeholder}
          error={error as any}
          onChange={onChange}
          onBlur={onBlur}
        />
      );
    }}
  />
);
