import { Button, MantineSize } from "@mantine/core";
import { ButtonBody } from "shared/types/table";

export type TableButtonProps = {
  body: ButtonBody;
  size: MantineSize | string | undefined;
};
export const TableButton = ({ size, body }: TableButtonProps) => {
  return (
    <Button size={"xs"} onClick={body.onClick} disabled={body.disabled}>
      {body.value}
    </Button>
  );
};
