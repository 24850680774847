import {
  CheckSMSLocationState,
  CheckSMSStates,
  UseCheckSMSReturn,
} from "pages/_service/CheckSMSPage/types";
import React from "react";
import { useLocation } from "react-router-dom";
import smsFx from "shared/api/sms";
import { useFetch } from "shared/hooks/useFetch";

export const useCheckSMS = (): UseCheckSMSReturn => {
  const locationState = useLocation().state as CheckSMSLocationState;
  const checkIsExist = useFetch(smsFx.checkIsExistAndActive);

  const smsSignatureId = locationState?.id;
  const states: { [key in CheckSMSStates]: boolean } = {
    error: Boolean(checkIsExist.error || !smsSignatureId),
    pending: checkIsExist.pending,
    check: Boolean(checkIsExist.data),
  };
  const state = Object.keys(states).find((key) => states[key as CheckSMSStates]) as
    | CheckSMSStates
    | undefined;

  React.useEffect(() => {
    if (checkIsExist.data || !smsSignatureId) return;

    checkIsExist.fetchData({ smsSignatureId });
  }, [checkIsExist.data]);

  return {
    state,
    locationState,
  };
};
