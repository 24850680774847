import { Sizes } from "shared/types/mantine";
import { AllTypes } from "shared/types/table";
import { TableContentConfig } from "./config";

type Props = {
  body: AllTypes;
  size?: Sizes;
};

const { content } = TableContentConfig;

export const TableContent = (props: Props) => {
  const { body } = props;

  return content[body.type](props as any);
};
