export const validateOgrnip = (
  ogrnip: number | string,
  error: {
    code?: number;
    message?: string;
  } = {},
) => {
  var result = false;
  if (typeof ogrnip === "number") {
    ogrnip = ogrnip.toString();
  } else if (typeof ogrnip !== "string") {
    ogrnip = "";
  }
  if (!ogrnip.length) {
    error.code = 1;
    error.message = "ОГРНИП пуст";
  } else if (/[^0-9]/.test(ogrnip)) {
    error.code = 2;
    error.message = "ОГРНИП может состоять только из цифр";
  } else if (ogrnip.length !== 15) {
    error.code = 3;
    error.message = "ОГРНИП может состоять только из 15 цифр";
  } else {
    var n15 = parseInt((parseInt(ogrnip.slice(0, -1)) % 13).toString().slice(-1));
    if (n15 === parseInt(ogrnip[14])) {
      result = true;
    } else {
      error.code = 4;
      error.message = "Неправильное контрольное число";
    }
  }
  return result;
};
