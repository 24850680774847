import { CenterContainer } from "shared/ui/_containers/CenterContainer";
import { MainLoader } from "shared/ui/_loaders/MainLoader";

export const CheckSMSPending = () => {
  return (
    <CenterContainer>
      <MainLoader />
    </CenterContainer>
  );
};
