import {
  CardSettingsCheckItemProps,
  CardSettingsEditItem,
  cardSettingsFx,
  CardSettingsGetByOwner,
} from "shared/api/_project/cardSettings";
import { CardSettings } from "shared/types/projects";
import { FxSlice } from "shared/types/store";
import { createSimpleStore } from "stores/_helpers/factories/createSimpleStore";

type Fxs = {
  fetchGetItem: FxSlice<CardSettingsGetByOwner>;
  fetchSetItem: FxSlice<CardSettingsEditItem>;
  fetchCheckItem: FxSlice<CardSettingsCheckItemProps>;
};

const { getItemByOwner, editItemByOwner, checkItem } = cardSettingsFx;

export const useCardSettingsStore = createSimpleStore<CardSettings, Fxs>({
  fetchGetItem: getItemByOwner,
  fetchSetItem: editItemByOwner,
  fetchCheckItem: checkItem,
});
