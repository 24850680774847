import React from "react";
import { useNavigate } from "react-router-dom";
import { searchParamsToPath } from "../helpers/searchParamsToPath";
import { useSearchParamsImproved } from "./useSearchParamsImproved";

type Props = { path: string; dependencies: any[]; condition?: boolean };

type UseNavigateTo = (props: Props) => void;

export const useNavigateTo: UseNavigateTo = ({ path, dependencies, condition }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParamsImproved();

  React.useEffect(() => {
    condition && navigate(path + "?" + searchParamsToPath(searchParams), { replace: true });
  }, dependencies);
};
