import { Stack } from "@mantine/core";
import { useActualProjectOffer } from "modules/investorRole/hooks/storeConnectors/offers/useActualProjectOffer";
import { useProjectOffers } from "modules/investorRole/hooks/storeConnectors/offers/useProjectOffers";
import { useParams } from "react-router-dom";
import { OFFER_STATUSES } from "shared/const/offers";
import { toFormatDate } from "shared/helpers/toFormatDate";
import { useCreateTableBody } from "shared/hooks/useCreateTableBody";
import { Offer } from "shared/types/projects";
import { BodyTypes, CreateRow } from "shared/types/table";
import { TablePaper } from "shared/ui/_tables/TablePaper";

type Config = {
  titles: string[];
  dealRow: CreateRow<Offer>;
};
export const filterDealsConfig: Config = {
  titles: ["Номер инвестпредложения", "Статус", "Период размещения", "Собрано", ""],
  dealRow: ({ data }) => ({
    offerNumber: {
      value: "№" + data.offerNumber,
      type: BodyTypes.text,
    },
    status: {
      value: data.status,
      type: BodyTypes.status,
      variants: OFFER_STATUSES,
    },
    period: {
      value: `${toFormatDate(data.startDate)} - ${toFormatDate(data.endDate)}`,
      type: BodyTypes.text,
    },
    raised: {
      value: data.raised,
      type: BodyTypes.price,
    },
    raisedPercent: {
      value: {
        positive: data.raised,
        negative: data.target,
      },
      type: BodyTypes.progress,
    },
  }),
};
const ProjectOffersPage = () => {
  const { id = "" } = useParams();

  const { data: actualOfferData, pending: actualOfferPending } = useActualProjectOffer({
    projectId: id,
  });
  const { data: otherOffersData, pending: otherOffersPending } = useProjectOffers({
    params: { projectId: id, status: ["completed", "cancelled", "completedEarlier"] },
  });
  const actualOfferTableBody = useCreateTableBody({
    data: actualOfferData,
    create: filterDealsConfig.dealRow,
  });
  const otherOffersTableBody = useCreateTableBody({
    data: otherOffersData,
    create: filterDealsConfig.dealRow,
  });

  const pending = actualOfferPending || otherOffersPending;

  return (
    <Stack gap={"lg"}>
      <TablePaper
        title={"Текущее"}
        pending={pending}
        size={"sm"}
        titles={filterDealsConfig.titles}
        body={actualOfferTableBody}
      />
      <TablePaper
        title={"Завершенные"}
        pending={pending}
        size={"sm"}
        titles={filterDealsConfig.titles}
        body={otherOffersTableBody}
      />
    </Stack>
  );
};

export default ProjectOffersPage;
