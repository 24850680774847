import { FileButton as MantineFileButton, FileButtonProps } from "@mantine/core";
import { FileRejection } from "@mantine/dropzone";
import { notifications } from "@mantine/notifications";
import { CONST } from "shared/const";
import { getFileUploadErrors } from "shared/helpers/getFileUploadError";

export const FileButton = <Multiple extends boolean = false>({
  children,
  onChange,
  maxSize = CONST.VALUES.fileUploadMaxSize,
  accept,
  onFailure,
  ...rest
}: FileButtonProps<Multiple> & {
  onFailure?: (rejections: FileRejection[]) => void;
  maxSize?: number;
}) => {
  const onFileUploadError = (rejections: FileRejection[]) => {
    const errors = getFileUploadErrors(rejections, {
      accept: accept,
      maxSize: maxSize,
    });

    errors.forEach((err) => {
      notifications.show({
        title: err.title,
        message: err.message,
        color: "red",
      });
    });

    onFailure?.(rejections);
  };

  const getRejections = (
    files: File[],
    {
      maxSize,
      accept,
    }: {
      maxSize?: number;
      accept?: string;
    },
  ): FileRejection[] => {
    return files.reduce((acc, file) => {
      const errors: FileRejection["errors"] = [];
      if (file.size >= (maxSize || 0)) {
        errors.push({
          code: "file-too-large",
          message: `Размер файла не должен превышать ${maxSize} байт`,
        });
      }

      if (accept && !accept.includes(file.type)) {
        errors.push({
          code: "file-invalid-type",
          message: `Разрешённые типы файла: ${accept}`,
        });
      }

      if (errors.length > 0) {
        acc.push({
          file,
          errors,
        });
      }

      return acc;
    }, [] as FileRejection[]);
  };

  const onChangeFile = (payload: Multiple extends true ? File[] : File | null) => {
    if (!payload) return;

    const files = (Array.isArray(payload) ? payload : [payload]) as File[];

    const fileRejections = getRejections(files, {
      accept,
      maxSize,
    });

    if (fileRejections.length > 0) {
      return onFileUploadError(fileRejections);
    }

    onChange(payload);
  };

  return (
    <MantineFileButton {...rest} accept={accept} onChange={onChangeFile}>
      {children}
    </MantineFileButton>
  );
};
