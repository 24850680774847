import {
  AspectRatio,
  Box,
  Button,
  FileButton,
  Image,
  Loader,
  Overlay,
  Paper,
  Text,
  useMantineTheme,
} from "@mantine/core";
import React from "react";
import { useWatch } from "react-hook-form";
import { uploadFileFX } from "shared/api/files";
import { useFetch } from "shared/hooks/useFetch";
import { useSessionID } from "shared/hooks/_auth/useSessionID";
import { CenterContainer } from "shared/ui/_containers/CenterContainer";
import { ErrorText } from "shared/ui/_typography/text/ErrorText";
import styles from "./index.module.scss";

const SIZES = {
  xs: 50,
  sm: 75,
  md: 100,
  lg: 125,
  xl: 150,
};
export const LogoInput = ({ control, setValue, name, error, size = "md" }: any) => {
  const logo = useWatch({ control, name });
  const { pending, data: newLogo, fetchData } = useFetch(uploadFileFX);
  const sessionID = useSessionID()!;
  const theme = useMantineTheme();

  React.useEffect(() => {
    if (!newLogo) return;

    setValue(name, newLogo);
  }, [newLogo]);

  const handleUploadFile = async (file: File | null) => {
    if (!file) return;

    await fetchData({ sessionID, file });
  };

  const overlay = (
    <Overlay radius={"lg"} display={"flex"} className={styles.overlay}>
      <FileButton accept="image/png,image/jpeg" onChange={handleUploadFile}>
        {(props) => (
          <Button {...props} loading={pending} size={"sm"} variant={"default"}>
            {logo ? "Изменить" : "Добавить"}
          </Button>
        )}
      </FileButton>
    </Overlay>
  );

  const loader = (
    <CenterContainer w={SIZES[size as keyof typeof SIZES]}>
      <Loader />
    </CenterContainer>
  );

  const imageBlock = (
    <>
      <AspectRatio w={SIZES[size as keyof typeof SIZES]}>
        {logo ? (
          <Image src={logo} style={{ objectFit: "contain" }} />
        ) : (
          <CenterContainer>Нет лого</CenterContainer>
        )}
      </AspectRatio>
      {overlay}
    </>
  );

  const redColor = theme.colors.error[6];

  const errorBorder = error && {
    root: { outline: `2px solid ${redColor}` },
  };

  return (
    <Box>
      <Text mb={10} fw={600} size={size}>
        Лого
      </Text>
      <Paper mb={5} bg={"white"} w={"min-content"} p={5} pos={"relative"} styles={errorBorder}>
        {pending ? loader : imageBlock}
      </Paper>
      <ErrorText error={error}></ErrorText>
    </Box>
  );
};
