import { Avatar, Box, CloseButton, Group, Paper, Text, Title } from "@mantine/core";
import { CardSettingsTeamMemberContext } from "modules/projectRole/pages/CardSettingsPage/pages/CardSettingsTeamPage";
import { useContext } from "react";
import { TeamMember } from "shared/types/projects";
import styles from "./index.module.scss";

type Props = {
  index: number;
} & TeamMember;
export const ProjectTeamMember = ({ photo, firstName, lastName, position, index }: Props) => {
  const fullName = `${firstName} ${lastName}`;
  const teamMember = useContext(CardSettingsTeamMemberContext);

  const onDelete = () => {
    teamMember?.handleDeleteTeamMember(index);
  };

  return (
    <Paper bg={"dark.9"}>
      <Group align={"center"} justify={"space-between"}>
        <Group align={"center"} gap={"sx"}>
          <Avatar size={"lg"} src={photo} data-index={index} className={styles.avatar} />
          <Box>
            <Title order={4}>{fullName}</Title>
            <Text>{position}</Text>
          </Box>
        </Group>
        {teamMember && <CloseButton onClick={onDelete} />}
      </Group>
    </Paper>
  );
};
