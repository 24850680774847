import { LazyMainPage } from "modules/projectRole/pages/MainPage/lazy";
import { ROUTES } from "shared/const/ROUTES";
import { AnyObject } from "shared/types/common";
import { Route } from "shared/types/routes";

export const projectRoleMainRoute: AnyObject<Route> = {
  projectManager: {
    Element: LazyMainPage,
    path: ROUTES.PROJECT.MAIN,
  },
};
