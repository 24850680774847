import { useCheckAuth } from "stores/useAuth/stores/useCheckAuth";
import { resetStore } from "stores/_helpers/factories/resetStore";
import { useAuth } from "../../stores/useAuth";

type Payload = () => void;
type UseLogout = () => Payload;

export const useLogout: UseLogout = () => {
  const { resetAuth } = useAuth();
  const { reset } = useCheckAuth();

  const handleLogout = () => {
    reset();
    resetAuth();
    localStorage.clear();
    sessionStorage.clear();
    resetStore();
  };

  return handleLogout;
};
