import { Badge, Group, MantineSize } from "@mantine/core";
import { GroupBody } from "shared/types/table";

export type TableGroupProps = {
  body: GroupBody;
  size: MantineSize | string | undefined;
};
export const TableGroup = ({ size, body }: TableGroupProps) => {
  const item = body.value.map((value, i) => <Badge key={i}>{value}</Badge>);
  return <Group gap={size}>{item}</Group>;
};
