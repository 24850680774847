import React from "react";
import { SetURLSearchParams, useSearchParams } from "react-router-dom";
import { AnyObject } from "shared/types/common";

type Keys = string[];

type UseSearchParamsCustom = (
  key?: Keys,
) => [AnyObject<string>, SetURLSearchParams, AnyObject<string>, (key: string) => void];

type Params = AnyObject<string>;

export const useSearchParamsImproved: UseSearchParamsCustom = (keys) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [params, setParams] = React.useState<Params>(() => {
    const newParams: AnyObject<string> = {};
    for (const [param, value] of searchParams) {
      if (!keys || keys.includes(param)) {
        newParams[param] = value;
      }
    }
    return newParams;
  });
  const [allParams, setAllParams] = React.useState<Params>({});
  const setNewParams = (set: React.Dispatch<Params>, keys?: string[]) => {
    const newParams: AnyObject<string> = {};
    for (const [param, value] of searchParams) {
      if (!keys || keys.includes(param)) {
        newParams[param] = value;
      }
    }
    set(newParams);
  };

  const deleteParams = (key: string) => {
    const param = searchParams.get(key);

    if (param) {
      searchParams.delete(key);
      setSearchParams(searchParams);
    }
  };

  const hasKeys = (searchParams: URLSearchParams) => {
    if (!keys) return false;
    for (const [key] of searchParams) {
      if (!keys.includes(key)) return false;
      return true;
    }
  };

  React.useEffect(() => {
    if (hasKeys(searchParams)) setNewParams(setParams, keys);
    setNewParams(setAllParams);
  }, [searchParams]);

  return [params, setSearchParams, allParams, deleteParams];
};
