import { LazyCreateProjectPage } from "modules/projectRole/pages/CreateProjectPage/lazy";
import { ROUTES } from "shared/const/ROUTES";
import { AnyObject } from "shared/types/common";
import { Route } from "shared/types/routes";

export const projectRoleNewProjectRoute: AnyObject<Route> = {
  newProject: {
    Element: LazyCreateProjectPage,
    path: ROUTES.STEP_FORMS.CREATE_PROJECT,
  },
};
