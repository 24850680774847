import React from "react";
import { useUser } from "stores/accounts/useUser";
import { useCheckAuth as useCheckAuthStore } from "stores/useAuth/stores/useCheckAuth";
import { useSessionID } from "./useSessionID";

export const useCheckAuth = () => {
  const { data, ...checkAuth } = useCheckAuthStore();
  const sessionID = useSessionID()!;
  const { pending, error, fx } = checkAuth.fetchCheckAuth;
  const setUser = useUser(({ user: { set } }) => set);

  React.useEffect(() => {
    if (!data && !pending) fx({ sessionID });
  }, [data]);

  React.useEffect(() => {
    if (data) setUser({ data });
  }, [data]);

  return { pending, error, checkAuth, data };
};
