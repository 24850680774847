export const REGEX = {
  noNumbers: /[^\d]/g,
  phone: /^(\+7 \(\d{3}\) \d{3}-\d{2}-\d{2})$/,
  orgPhone: /^8 \(\d{3}\) \d{3}-\d{2}-\d{2}$/,
  standardDate: /^\d{4}-\d{2}-\d{2}$/,
  ruDate: /^\d{2}\.\d{2}\.\d{1,4}$/,
  url: /(https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*))/gi,
  youtube:
    /(?:https?:\/\/)?(?:www\.)(?:youtube\.com\/watch\?v=|youtu\.be\/|youtube\.com\/shorts\/)([a-zA-Z0-9_-]+)/,
  cyrillic: /[\u0400-\u052F\u2DE0-\u2DFF\uA640-\uA69F]/g,
};
