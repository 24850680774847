import { ComponentRouter } from "components/_common/ComponentRouter";
import { CheckSMSContext } from "pages/_service/CheckSMSPage";
import { checkSMSRouterVariants } from "pages/_service/CheckSMSPage/components/CheckSMSRouter/config";
import { UseCheckSMSReturn } from "pages/_service/CheckSMSPage/types";
import { useContext } from "react";

export const CheckSMSRouter = () => {
  const { state } = useContext(CheckSMSContext) as UseCheckSMSReturn;

  if (state) return <ComponentRouter variants={checkSMSRouterVariants} currentKey={state} />;

  return null;
};
