import { MantineSize, Text } from "@mantine/core";
import { TableBalanceChange as TableBalanceChangeType } from "shared/types/table";
import { RubleNumberFormatter } from "shared/ui/_numberFormatters/RubleNumberFormatter";

const config = [
  { sign: "-", color: "red" },
  { sign: "+", color: "green" },
];
export type TableBalanceChangeProps = {
  body: TableBalanceChangeType;
  size: MantineSize | string | undefined;
};
export const TableBalanceChange = ({
  body: { isReceipt, value },
  size,
}: TableBalanceChangeProps) => {
  const index = Number(isReceipt);
  const { sign, color } = config[index];

  return (
    <Text c={color} size={size} style={{ textWrap: "nowrap" }}>
      {sign}
      <RubleNumberFormatter value={value} />
    </Text>
  );
};
