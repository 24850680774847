import { Skeleton, Table } from "@mantine/core";

type Props = {
  rowsAmount: number;
  columnsAmount: number;
};

export const SkeletonTable = ({ rowsAmount, columnsAmount }: Props) => {
  const rows = new Array(rowsAmount).fill(0);
  const columns = new Array(columnsAmount).fill(0);

  return (
    <>
      {rows.map((row, index) => (
        <Table.Tr key={index}>
          {columns.map((column, index) => (
            <Table.Td key={index}>
              <Skeleton height={20} mt={6} radius="xl" />
            </Table.Td>
          ))}
        </Table.Tr>
      ))}
    </>
  );
};
