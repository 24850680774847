import React from "react";
import { Hook } from "shared/types/hooks";

type Props = {
  dependencies?: any[];
  condition?: boolean;
};

export const useScrollTopWindow: Hook<Props, void> = ({ dependencies, condition = true }) => {
  React.useEffect(() => {
    if (condition) {
      window.scrollTo({
        top: 0,
      });
    }
  }, dependencies);
};
