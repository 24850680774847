import { MainLayout } from "components/_layouts/MainLayout";
import { CheckAuth } from "components/_private/CheckAuth";
import { investorRoleRoutes } from "modules/investorRole/router";
import { projectRoleRoutes } from "modules/projectRole/router";
import { AnyObject } from "shared/types/common";
import { Route } from "shared/types/routes";
import { othersRoutes } from "./routes/othersRoutes";

export const privateRoutes: AnyObject<Route> = {
  checkAuth: {
    Element: CheckAuth,
    children: {
      mainLayout: {
        path: "/",
        Element: MainLayout,
        children: {
          ...investorRoleRoutes,
          ...projectRoleRoutes,
          ...othersRoutes,
        },
      },
    },
  },
};
