import { Project } from "shared/types/projects";
import { SliceModern } from "stores/_helpers/factories/createSliceNew";
import { shallow } from "zustand/shallow";
import { createWithEqualityFn } from "zustand/traditional";
import { allProjectsSlice, AllProjectsSliceFxs } from "./slices/allProjectsSlice";
import { projectSlice, ProjectSliceFxs } from "./slices/projectSlice";

export type UseProjects = {
  allProjects: SliceModern<Project[], AllProjectsSliceFxs>;
  project: SliceModern<Project, ProjectSliceFxs>;
};

export const useProjects = createWithEqualityFn<UseProjects>(
  (set, get) => ({
    allProjects: allProjectsSlice(set, get),
    project: projectSlice(set, get),
  }),
  shallow,
);
