import { Controller, FieldError, FieldErrorsImpl, Merge } from "react-hook-form";
import { Sizes } from "shared/types/mantine";
import { MaskStyledInput } from "../_masks/MaskStyledInput";

type Props = {
  name: string;
  control: any;
  size?: Sizes;
  label?: string;
  required?: boolean;
  length?: number;
  placeholder?: string;
  error?: string | FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined;
  validate?: (value: string) => boolean | string;
  disabled?: boolean;
};

const rules = (required?: boolean) => ({
  required: required
    ? {
        value: true,
        message: "Поле обязательно для заполнения",
      }
    : {},
  pattern: {},
});

export const NumericInput = ({
  name,
  control,
  size,
  required,
  label,
  error,
  length,
  placeholder,
  validate,
  disabled,
}: Props) => (
  <Controller
    name={name}
    control={control}
    rules={{ validate, ...(rules(required) as any) }}
    render={({ field: { onChange, onBlur, value = "" } }) => {
      const mask = new Array(length).fill("#").join("");
      return (
        <MaskStyledInput
          onBlur={onBlur}
          value={value}
          label={label}
          error={error as any}
          mask={mask}
          definitions={{
            "#": /[0-9]/,
          }}
          placeholder={placeholder}
          onAccept={onChange}
          required={required}
          onChange={onChange}
          size={size as any}
          overwrite
          disabled={disabled}
        />
      );
    }}
  />
);
