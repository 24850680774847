import { Loader as LoaderMantine, MantineSize } from "@mantine/core";

type Props = {
  loading?: boolean;
  size?: MantineSize;
};
export const Loader = ({ loading, size = "xs" }: Props) => {
  if (!loading) return null;

  return <LoaderMantine size={size} />;
};
