import {
  AvailableKeyTypes,
  ComponentRouterType,
  PropsGenericType,
} from "components/_common/ComponentRouter/types";

export const ComponentRouter = <
  T extends AvailableKeyTypes,
  Props extends PropsGenericType = undefined,
>({
  variants,
  currentKey,
  props,
}: ComponentRouterType<T, Props>) => {
  return <>{variants[currentKey as T](props)}</>;
};
