import { ActionIcon } from "@mantine/core";
import { IconRefresh } from "@tabler/icons-react";
import { Sizes } from "shared/types/mantine";
import styles from "./index.module.scss";

type Props = {
  loading?: boolean;
  onClick?: () => void;
  size?: Sizes;
  pending?: boolean;
};

export const RefreshButton = (props: Props) => (
  <ActionIcon {...props} className={styles.button} variant={"transparent"} color={"dark.0"}>
    <IconRefresh className={styles.icon} data-active={props.pending} />
  </ActionIcon>
);
