import { CheckSMSRouter } from "pages/_service/CheckSMSPage/components/CheckSMSRouter";
import { useCheckSMS } from "pages/_service/CheckSMSPage/hook";
import { UseCheckSMSReturn } from "pages/_service/CheckSMSPage/types";
import { createContext } from "react";

export const CheckSMSContext = createContext<UseCheckSMSReturn | null>(null);

const CheckSmsPage = () => {
  const useCheckSMSReturn = useCheckSMS();

  return (
    <CheckSMSContext.Provider value={useCheckSMSReturn}>
      <CheckSMSRouter />
    </CheckSMSContext.Provider>
  );
};

export default CheckSmsPage;
