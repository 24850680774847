import { API } from "./api";
import { REGEX } from "./regex";
import { REQUISITES } from "./requisites";
import { ROUTES } from "./ROUTES";
import { VALUES } from "./values";
import { FORMS } from "./_forms";

export const CONST = {
  API,
  REGEX,
  PAGES: ROUTES,
  FORMS,
  REQUISITES,
  VALUES,
};
