import { NewsPageLazy } from "modules/investorRole/pages/NewsPage/lazy";
import { NewPageLazy } from "modules/investorRole/pages/NewsPage/NewPage/lazy";
import { AnyObject } from "shared/types/common";
import { Route } from "shared/types/routes";

export const newsRoutes: AnyObject<Route> = {
  news: {
    Element: NewsPageLazy,
    path: "news",
  },
  new: {
    Element: NewPageLazy,
    path: "news/:id",
  },
};
