import AuthLayout from "components/_layouts/AuthLayout";
import React from "react";
import { AnyObject } from "shared/types/common";
import { Route } from "shared/types/routes";
import { CheckNoAuth } from "../../../components/_private/CheckNoAuth";

const SignInPage = React.lazy(() => import("pages/_auth/SignInPage"));
const SignUpPage = React.lazy(() => import("pages/_auth/SignUpPage"));

export const authRoutes: AnyObject<Route> = {
  checkNotAuth: {
    Element: CheckNoAuth,
    children: {
      layout: {
        path: "auth",
        Element: AuthLayout,
        children: {
          signIn: {
            path: "sign-in",
            Element: SignInPage,
          },
          signUp: {
            path: "sign-up",
            Element: SignUpPage,
          },
        },
      },
    },
  },
};
