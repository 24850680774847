import { ModernForm } from "shared/types/forms";
import { validateOgrnip } from "shared/ui/_forms/_common/validates/checkInn/isOgrnip";
import { isOrgInn } from "shared/ui/_forms/_common/validates/checkInn/isOrgInn";
import { isPersonalInn } from "shared/ui/_forms/_common/validates/checkInn/IsPersonalInn";

export const accountIndividualForm: ModernForm = [
  {
    name: "innPersonal",
    type: "numeric",
    label: "Личный ИНН",
    length: 12,
    placeholder: "262600375746",
    required: true,
  },
  {
    name: "accountNumber",
    type: "numeric",
    label: "Номер счета",
    length: 20,
    placeholder: "40817810099910004312",
    required: true,
  },
  {
    name: "innBank",
    type: "numeric",
    label: "ИНН Банка",
    length: 10,
    placeholder: "9705120864",
    required: true,
    validate: (value: string) => {
      const multiplied = [7, 2, 4, 10, 3, 5, 9, 4, 6, 8, 0];
      const innNumbers = value.split("").map((num) => Number(num));
      const sumOfProducts = multiplied.reduce(
        (acc, value, index) => acc + value * innNumbers[index],
        0,
      );
      const lastInnNumber = innNumbers[innNumbers.length - 1];
      return sumOfProducts % 11 === lastInnNumber;
    },
  },
];

export const accountEntrepreneurForm = [
  {
    name: "innPersonal",
    type: "numeric",
    label: "Личный ИНН",
    length: 12,
    placeholder: "262600375746",
    required: true,
    validate: isPersonalInn,
  },
  {
    name: "ogrnip",
    type: "numeric",
    label: "ОГРНИП",
    length: 15,
    placeholder: "316861700133226",
    validate: validateOgrnip,
    required: true,
  },
  {
    name: "registrationDate",
    type: "date",
    label: "Дата регистрации ИП",
    maxDate: new Date(),
    placeholder: "Выберите дату",
    required: true,
  },
  {
    name: "accountNumber",
    type: "numeric",
    label: "Номер счета",
    length: 20,
    placeholder: "40817810099910004312",
    required: true,
  },
  {
    name: "innBank",
    type: "numeric",
    label: "ИНН Банка",
    length: 10,
    placeholder: "9705120864",
    required: true,
    validate: isOrgInn,
  },
] satisfies ModernForm;
