import { Text } from "@mantine/core";
import { Sizes } from "../../../../../../types/mantine";
import styles from "../../ImageInput/index.module.scss";

type Props = {
  label?: string;
  required?: boolean;
  size?: Sizes;
};
export const LabelInput = ({ label, required, size }: Props) => {
  const requiredStar = required && (
    <Text span className={styles.requiredButton} c={"error"}>
      *
    </Text>
  );

  return (
    <Text fw={500} size={size} className={styles.inputLabel}>
      {label || "Поле"} {requiredStar}
    </Text>
  );
};
