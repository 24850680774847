import { Box, Center, Container, Flex } from "@mantine/core";
import { useLocation } from "react-router-dom";
import { toArray } from "shared/helpers/toArray";
import { useLogout } from "shared/hooks/useLogout";
import { NavbarData } from "shared/types/navbar";
import BootstrapGrid from "shared/ui/BootstrapGrid";
import styles from "./Mobile.module.scss";
import { menuItems, navbarItems } from "./_linksAndButtons";

//

export function MobileNavbar({ config }: { config: NavbarData }) {
  const handleLogout = useLogout();
  const location = useLocation();
  const mainRoute = toArray(location.pathname, "/")[1];
  const buttonActions = {
    logout: handleLogout,
  };

  const links = config.map((item) => {
    return item.type === "menu"
      ? navbarItems.menu(
          item,
          item.children.map((child: any) =>
            menuItems[child.type](child as any, buttonActions, mainRoute),
          ),
        )
      : navbarItems[item.type](item as any, buttonActions, mainRoute);
  });

  return (
    <Box component="footer" className={styles.footer}>
      <Box component="nav" className={styles.navbar}>
        <Container h={"inherit"} p={0} size={"xxl"}>
          <BootstrapGrid.Row component={Center} h={"100%"}>
            <BootstrapGrid.Item
              component={Flex}
              col={12}
              without="Pb"
              justify="space-between"
              gap={"sm"}
            >
              {links}
            </BootstrapGrid.Item>
          </BootstrapGrid.Row>
        </Container>
      </Box>
    </Box>
  );
}
