import {
  Anchor,
  Box,
  Center,
  Divider,
  Flex,
  Loader,
  Paper,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { IconFileText } from "@tabler/icons-react";
import { ROUND_DOCS } from "shared/const/projects";
import { useProject } from "shared/hooks/_projects/useProject";
import { RoundDocs } from "shared/types/projects";

const ProjectDocsPage = () => {
  const { data: project, pending } = useProject({ withoutEffect: true });

  if (pending)
    return (
      <Paper>
        <Center>
          <Loader />
        </Center>
      </Paper>
    );

  if (!project) return null;

  const companyDocsLinks = (
    <Box>
      <Anchor href={project.docs.charterDoc} target={"_blank"}>
        <Flex gap={"xs"} align={"center"}>
          <IconFileText />
          <Text>Устав</Text>
        </Flex>
      </Anchor>
    </Box>
  );

  const roundDocsLinks = Object.values(ROUND_DOCS).map(({ value, label }, index, array) => (
    <Box key={index}>
      <Anchor href={project.activeRound[value as keyof RoundDocs]} target={"_blank"}>
        <Flex gap={"xs"} align={"center"}>
          <IconFileText />
          <Text>{label}</Text>
        </Flex>
      </Anchor>
      {index !== array.length - 1 && <Divider mt={"sm"} />}
    </Box>
  ));

  const investmentDocsLinks = (
    <Box>
      <Anchor href={project.activeRound.activeOffer.offerInvestmentFile} target={"_blank"}>
        <Flex gap={"xs"} align={"center"}>
          <IconFileText />
          <Text>Инвестиционное предложение</Text>
        </Flex>
      </Anchor>
    </Box>
  );

  return (
    <Stack gap={"lg"}>
      <Paper component={Stack}>
        <Title order={4}>Документы компании</Title>
        <Stack gap={"sm"}>{companyDocsLinks}</Stack>
      </Paper>
      <Paper component={Stack}>
        <Title order={4}>Документы раунда</Title>
        <Stack gap={"sm"}>{roundDocsLinks}</Stack>
      </Paper>
      <Paper component={Stack}>
        <Title order={4}>Документы инвестпредложения</Title>
        <Stack gap={"sm"}>{investmentDocsLinks}</Stack>
      </Paper>
    </Stack>
  );
};

export default ProjectDocsPage;
