import { Box } from "@mantine/core";
import React from "react";
import styles from "./index.module.scss";

type Props = {
  children: React.ReactNode | React.ReactNode[];
  w?: string | number;
};

export const CenterContainer = ({ children, w = "auto" }: Props) => (
  <Box w={w} h={w} className={styles.container}>
    {children}
  </Box>
);
