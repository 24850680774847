import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Stack } from "@mantine/core";
import { FormProvider, useForm } from "react-hook-form";
import { ModernForm } from "shared/types/forms";
import { Sizes } from "shared/types/mantine";
import { Form } from "shared/ui/_forms";
import { ZodObject } from "zod";
import { FormInputs } from "../_common/FormInputs";

export type FormTemplateProps = {
  form: ModernForm;
  formSchema?: ZodObject<any, any, any>;
  textButton?: string;
  onSubmit: (form: any) => void;
  loading?: boolean;
  size?: Sizes;
  gap?: Sizes;
  error?: string | null;
  withoutButton?: boolean;
  resetAfterSubmit?: boolean;
};

export const FormTemplate = ({
  form,
  onSubmit,
  size,
  gap,
  loading,
  textButton,
  error,
  withoutButton,
  resetAfterSubmit,
  formSchema,
}: FormTemplateProps) => {
  const methods = useForm({
    resolver: formSchema && zodResolver(formSchema),
  });
  const {
    control,
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
  } = methods;

  const onSubmitForm = (form: any) => {
    onSubmit(form);
    if (resetAfterSubmit) reset();
  };

  return (
    <FormProvider {...methods}>
      <Form gap={gap} control={control} onSubmit={handleSubmit(onSubmitForm)} error={error}>
        <FormInputs form={form} control={control} setValue={setValue} errors={errors} size={size} />
        <Stack gap={"xs"}>
          {!withoutButton && (
            <Button type={"submit"} loading={loading} size={size}>
              {textButton}
            </Button>
          )}
        </Stack>
      </Form>
    </FormProvider>
  );
};
