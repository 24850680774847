import { Center, Container, Image, Paper } from "@mantine/core";
import { CheckSMSForm } from "pages/_service/CheckSMSPage/components/CheckSMSRouter/variants/CheckSMSCheck/CheckSMSForm";
import logo from "shared/assets/images/logo.png";

export const CheckSMSCheck = () => {
  return (
    <Container mt={50} size={600} w="fit-content">
      <Center>
        <Image w={200} src={logo} />
      </Center>
      <Paper mt={20}>
        <CheckSMSForm />
      </Paper>
    </Container>
  );
};
