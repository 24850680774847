import { Avatar, MantineSize } from "@mantine/core";
import { PictureBody } from "shared/types/table";

export type TablePictureProps = {
  body: PictureBody;
  size: MantineSize | string | undefined;
};
export const TablePicture = ({ size, body }: TablePictureProps) => {
  return <Avatar src={(body as PictureBody).value}></Avatar>;
};
