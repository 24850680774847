import { Button, Flex, Group, Stack } from "@mantine/core";
import { IconLogout, IconRocket } from "@tabler/icons-react";
import cx from "clsx";
import { Link, NavLink, useLocation } from "react-router-dom";

import logo from "shared/assets/images/logo.png";
import { useLogout } from "shared/hooks/useLogout";

import { getLinkPath } from "shared/helpers/getLinkPath";
import { toArray } from "shared/helpers/toArray";
import { NavbarData } from "shared/types/navbar";
import styles from "./Desktop.module.scss";

export function DesktopNavbar({ config }: { config: NavbarData }) {
  const handleLogout = useLogout();
  const location = useLocation();

  const mainRoute = toArray(location.pathname, "/")[1];

  const links = config.map((item) => {
    if (item.type === "investment") {
      return (
        <Button key={item.link} component={Link} to={item.link} leftSection={<IconRocket />}>
          Инвестировать
        </Button>
      );
    }
    if (item.type === "link") {
      return (
        <NavLink
          key={item.link}
          className={cx(styles.link)}
          data-active={item.link === mainRoute || null}
          to={getLinkPath(item.link, item.param)}
        >
          <item.icon className={styles.linkIcon} stroke={1.5} />
          <span>{item.label}</span>
        </NavLink>
      );
    }
    return null;
  });

  return (
    <nav className={styles.navbar}>
      <div className={styles.navbarMain}>
        <Group className={styles.header} justify="space-between">
          <Flex align={"center"} justify={"center"} gap={"xs"}>
            <img alt={"logo"} src={logo} width={140} />
          </Flex>
        </Group>
        <Stack className={styles.menu} gap={"md"}>
          {links}
        </Stack>
      </div>

      <div className={styles.footer}>
        <a className={styles.link} onClick={handleLogout}>
          <IconLogout className={styles.linkIcon} stroke={1.5} />
          <span>Выйти</span>
        </a>
      </div>
    </nav>
  );
}
