import { ComponentRouterVariants } from "components/_common/ComponentRouter/types";
import { CheckSMSRouterStatesProps } from "pages/_service/CheckSMSPage/components/CheckSMSRouter/types";
import { CheckSMSCheck } from "pages/_service/CheckSMSPage/components/CheckSMSRouter/variants/CheckSMSCheck";
import { CheckSMSError } from "pages/_service/CheckSMSPage/components/CheckSMSRouter/variants/CheckSMSError";
import { CheckSMSPending } from "pages/_service/CheckSMSPage/components/CheckSMSRouter/variants/CheckSMSPending";
import { CheckSMSStates } from "pages/_service/CheckSMSPage/types";

export const checkSMSRouterVariants: ComponentRouterVariants<
  CheckSMSStates,
  CheckSMSRouterStatesProps
> = {
  error: CheckSMSError,
  pending: CheckSMSPending,
  check: CheckSMSCheck,
};
