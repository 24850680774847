import { Fx, PagesParams, SIDParams } from "shared/types/api";
import { CreateDeal, Deal } from "../types/deals";
import { createApi } from "./_factories/createApi";

const structure = "deals";
const api = createApi({ structure });

export type CreateDealFxProps = {
  params: SIDParams;
  data: CreateDeal;
};

type PostResult = Deal;

const create: Fx<CreateDealFxProps, PostResult, Error> = async ({ data, params }) =>
  await api().setItem<PostResult>("create", data, params);

type Statuses = {
  status: string;
};

export type GetAllDealsParams = SIDParams & PagesParams & Statuses;

const getAll: Fx<GetAllDealsParams, Deal[], Error> = async ({ pageSize = 30, ...otherParams }) =>
  await api().getItems<Deal[]>("getAll", { pageSize, ...otherParams });

export type SignFxProp = {
  params: SIDParams;
  data: { id: string };
};
const sign: Fx<SignFxProp, Deal, Error> = async ({ params, data }) =>
  api().setItem<Deal>("sign", data, params);

export type GetByIdDealParams = { id: string } & SIDParams;

const getById: Fx<GetByIdDealParams, Deal, Error> = async (params) =>
  await api().getItem<Deal>("getAll", params);

export type CancelFxProps = {
  params: SIDParams;
  data: { investorId: string; dealId: string };
};

export type CancelFxPayload = {
  smsSignature: string;
};
const cancel: Fx<CancelFxProps, CancelFxPayload, Error> = async ({ data, params }) =>
  await api("canceldeal").setItem<CancelFxPayload>("cancel", data, params);

// Работа
export type DealsProjectsFxProps = any;
export type DealsProjectsFxPayload = any;

const dealsProjects: Fx<DealsProjectsFxProps, DealsProjectsFxPayload, Error> = async () =>
  await api().getItem<DealsProjectsFxPayload>("projects");
// Работа

export const dealsFx = { create, getAll, getById, sign, cancel, dealsProjects };
