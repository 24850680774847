import { createApi } from "shared/api/_factories/createApi";
import { OFFER_STATUSES } from "shared/const/offers";
import { DefaultGetItemParams, DefaultGetItemsParams, Fx, SIDParams } from "shared/types/api";
import { CreateOffer, Offer } from "shared/types/projects";

const structure = "offers";
const api = createApi({ structure });

export type GetOffersParams = {
  round?: string;
  status?: string;
} & DefaultGetItemsParams;

const getItems: Fx<GetOffersParams, Offer[], Error> = (params) => api().getItems("getAll", params);

const getCreateOffer: Fx<DefaultGetItemParams, CreateOffer, Error> = (params) =>
  api("createoffer").getItem("getActive", params);

export type GetOfferByProjectIdParams = {
  projectId: string;
  status: (keyof typeof OFFER_STATUSES)[];
} & DefaultGetItemsParams;

const getOffersByProjectId: Fx<GetOfferByProjectIdParams, Offer[], Error> = (params) =>
  api().getItems("getByProjectId", params);

export type SetReceivedOfferInvestmentsProps = {
  params: SIDParams;
  data: {
    id: string;
  };
};

const setReceivedOfferInvestments: Fx<SetReceivedOfferInvestmentsProps, { id: string }, Error> = ({
  data,
  params,
}) => api().setItem("setRecievedOfferInvestment", data, params);

export const offersFx = {
  getItems,
  getCreateOffer,
  getOffersByProjectId,
  setReceivedOfferInvestments,
};
