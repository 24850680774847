import { CheckSMSResendButtonProps } from "pages/_service/CheckSMSPage/components/CheckSMSRouter/variants/CheckSMSCheck/CheckSMSForm/CheckSMSResendButton/types";
import { TimerButton } from "shared/ui/_buttons/TimerButton";

export const CheckSMSResendButton = ({
  handleResend,
  pending,
  disabled,
}: CheckSMSResendButtonProps) => {
  return (
    <TimerButton
      variant={"light"}
      size={"lg"}
      onClick={handleResend}
      loading={pending}
      disabled={disabled}
      text={"Отправить еще код"}
      startTime={30}
      error={null}
    />
  );
};
