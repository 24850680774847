import { LazyRegistriesPage } from "modules/projectRole/pages/RegistriesPage/lazy";
import { ROUTES } from "shared/const/ROUTES";
import { AnyObject } from "shared/types/common";
import { Route } from "shared/types/routes";

export const registriesRoutes: AnyObject<Route> = {
  registries: {
    path: ROUTES.PROJECT.REGISTRIES.ROOT,
    Element: LazyRegistriesPage,
  },
};
