import { TextInput as TextInputComp } from "@mantine/core";
import { Controller, FieldError, FieldErrorsImpl, Merge } from "react-hook-form";
import { Sizes } from "shared/types/mantine";
import { PopoverInfoIcon } from "shared/ui/_forms/_common/_inputs/_components/TooltipInfoIcon";

type Props = {
  name: string;
  control: any;
  size?: Sizes;
  label?: string;
  required?: boolean;
  placeholder?: string;
  error?: string | FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined;
  disabled?: boolean;
  format?: (value: string) => string;
  tooltip?: string;
  combine?: {
    keys: string[] | string;
    fn: (values: { [p: string]: string }) => string;
  };
};

const rules = (required?: boolean) => ({
  required: required
    ? {
        value: true,
        message: "Поле обязательно для заполнения",
      }
    : {},
  pattern: {},
});

export const TextInput = ({
  name,
  control,
  size,
  required,
  label,
  error,
  placeholder,
  disabled,
  format,
  tooltip,
}: Props) => {
  return (
    <Controller
      name={name}
      defaultValue={""}
      control={control}
      rules={rules(required) as any}
      render={({ field: { onChange, onBlur, value } }) => {
        return (
          <TextInputComp
            size={size}
            value={value}
            label={label}
            rightSection={<PopoverInfoIcon visible={Boolean(tooltip)} label={tooltip} />}
            required={required}
            placeholder={placeholder}
            error={error as any}
            onChange={(e) => onChange(format ? format(e.target.value) : e.target.value)}
            onBlur={onBlur}
            disabled={disabled}
          />
        );
      }}
    />
  );
};
