import { Divider, Stack, TextInput as TextInputComp } from "@mantine/core";
import { Controller, FieldError, FieldErrorsImpl, Merge } from "react-hook-form";
import { Sizes } from "shared/types/mantine";

type Props = {
  name: string;
  control: any;
  size?: Sizes;
  label?: string;
  data?: string;
  required?: boolean;
  placeholder?: string;
  error?: string | FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined;
  disabled?: boolean;
  unstyledWhenDisabled?: boolean;
};

const rules = (required?: boolean) => ({
  required: required
    ? {
        value: true,
        message: "Поле обязательно для заполнения",
      }
    : {},
  pattern: {},
});

export const TextInput = ({
  name,
  control,
  size,
  required,
  label,
  error,
  placeholder,
  disabled,
  unstyledWhenDisabled,
  data,
}: Props) => (
  <Controller
    name={name}
    defaultValue={data}
    control={control}
    rules={rules(required) as any}
    render={({ field: { onChange, onBlur, value } }) => {
      const isEditMode = disabled && unstyledWhenDisabled;

      return (
        <Stack gap={"xs"}>
          <TextInputComp
            styles={{
              input: {
                backgroundColor: isEditMode ? "transparent" : undefined,
                paddingLeft: isEditMode ? 0 : undefined,
                color: isEditMode ? "var(--mantine-color-white)" : undefined,
              },
            }}
            withAsterisk={!disabled}
            disabled={disabled}
            size={size}
            value={value}
            label={label}
            required={required}
            placeholder={placeholder}
            error={error as any}
            onChange={onChange}
            onBlur={onBlur}
          />
          <Divider />
        </Stack>
      );
    }}
  />
);
